// import router from '@/router';
import axios from 'axios';
// import authorization from '@/api/authorization';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST || '/';
// axios.defaults.withCredentials = true;

// axios.defaults.headers.common.Authorization = localStorage.getItem('access_token');

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_HOST || '/',
});
HTTP.defaults.withCredentials = true;

export const callApi = (config) => {
  const result = HTTP(config).catch((error) => {
    if (error.response.status === 403) {
      HTTP(error.config);
    }
  });

  return result;
};

// HTTP.interceptors.request.use((config) => {
//   alert('test');
//   return config;
// }, (error) => {
//   console.log('kek');
//   // Do something with request error
//   return Promise.reject(error);
// });

// const createAxiosResponseInterceptor = () => {
//   const interceptor = axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       console.log('pek');
//       // Reject promise if usual error
//       if (error.response.status !== 401) {
//         return Promise.reject(error);
//       }

//       const refreshToken = localStorage.getItem('refresh_token');
//       if (!refreshToken) {
//         localStorage.setItem('access_token', null);
//         localStorage.setItem('refresh_token', null);
//         // eslint-disable-next-line no-restricted-globals
//         location.replace('/auth');
//         return Promise.reject(error);
//       }

//       /*
//        * When response code is 401, try to refresh the token.
//        * Eject the interceptor so it doesn't loop in case
//        * token refresh causes the 401 response
//        */
//       axios.interceptors.response.eject(interceptor);
//       console.log(123);
//       return axios
//         .post('/auth/refresh-bearer', { refreshToken })
//         .then(({ data }) => {
//           localStorage.setItem('access_token', data.access_token);
//           localStorage.setItem('refresh_token', data.refresh_token);
//           // eslint-disable-next-line no-param-reassign
//           error.response.config.headers.Authorization = data.access_token;
//           return axios(error.response.config);
//         })
//         .catch(() => {
//           localStorage.setItem('access_token', null);
//           localStorage.setItem('refresh_token', null);
//           // eslint-disable-next-line no-restricted-globals
//           location.replace('/auth');
//           return Promise.reject(error);
//         })
//         .finally(createAxiosResponseInterceptor);
//     },
//   );
// };

// axios.interceptors.request.use(
//   (config) => ({
//     ...config,
//     withCredentials: true,
//   }),
//   (error) => Promise.reject(error),
// );

// createAxiosResponseInterceptor();

// HTTP.interceptors.response.use(async (response) => response, async (error) => {
//   if (error.response.status === 401) {
//     if (!error.request.responseURL.includes('signup-bearer')) {
//       const refreshed = await authorization.refresh();
//       if (!refreshed) {
//         return Promise.reject(error);
//       }
//       return Promise.resolve();
//     }
//   }

//   if (error.request.responseURL.includes('/auth/')) {
//     return Promise.reject(error);
//   }
//   if (error.response.status === 400) {
//     await router.replace('/page-not-found');
//   }
//   return Promise.reject(error);
// });

// export default HTTP;
