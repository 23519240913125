<template>
  <section class="section section-banner">
    <div class="content">
      <div class="diagnostic">
        <div class="diagnostic-title">
          Больше половины людей не знают, что делать, если близкий столкнулся
          <span>с апатией, утратой или тревогой</span>
        </div>
        <div class="diagnostic-description">
          <ul>
            <li>Как не растеряться и стать опорой?</li>
            <li>Как помочь бережно и не сделать ещё хуже?</li>
            <li>Достаточно ли у меня навыков для помощи другому?</li>
          </ul>
        </div>
        <v-btn color="red" dark large @click="openDiagnostic"> Проверить навыки </v-btn>
        <div class="diagnostic-pic">
          <img
            v-if="$vuetify.breakpoint.mdAndDown"
            src="@/assets/images/banner/banner-mobile.png"
          />
          <img v-else src="@/assets/images/banner/banner-desktop.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SectionDiagnostic',

  computed: {
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
    }),
  },

  methods: {
    openDiagnostic() {
      const DIAGNOSTICS_FILENAME = process.env.VUE_APP_DIAGNOSTICS_FILENAME || 'diagnostics.json';
      const DIAGNOSTICS_URL = process.env.VUE_APP_DIAGNOSTICS_URL || 'localhost:9000';
      const link = document.createElement('a');
      link.href = `${window.location.protocol}//${DIAGNOSTICS_URL}/play?scenarioId=${DIAGNOSTICS_FILENAME}&local=true`;
      link.rel = 'noopener noreferrer';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-banner {
  padding-bottom: 0;
  @media (max-width: 767px) {
    background: white;
    position: relative;
    z-index: 1;
    padding-bottom: 22px !important;
    padding-top: 22px;
  }
  @media (max-width: 440px) {
    .content {
      padding-right: 0;
    }
  }
}
.content {
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    right: 50%;
    top: -30px;
    width: 3500px;
    height: 300px;
    margin-right: -1735px;
    background: url("~@/assets/images/designs/background-diag.png") center center no-repeat;
    background-size: contain;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.diagnostic {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 48px;
  margin: 26px auto 34px;
  border-radius: 12px;
  background: rgba(249, 249, 249, 1);
  box-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.1);
  z-index: 1;
  & > * {
    z-index: 1;
  }
  @media (max-width: 767px) {
    background: white;
    box-shadow: none;
    padding: 0;
    margin: 0 auto;
    height: 280px;
  }
}
.diagnostic-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.005em;
  span:last-child {
    color: rgba(219, 28, 28, 1);
  }
  margin-bottom: 12px;
  max-width: 640px;
  @media (max-width: 850px) and (min-width: 768px) {
    max-width: 560px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    max-width: 260px;
  }
}
.diagnostic-description {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  ul {
    margin: 0;
    padding-left: 0;
  }
  ul {
    list-style-type: none;
  }
  ul > li:before {
    content: "— ";
  }
  @media (max-width: 767px) {
    max-width: 220px;
    font-size: 13px;
  }
  @media (max-width: 440px) {
    max-width: 200px;
  }
}
.diagnostic-pic {
  position: absolute;
  height: 100%;
  z-index: 0;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 12px;
  overflow: hidden;
  img {
    height: 100%;
  }
  @media (max-width: 767px) {
    border-bottom-right-radius: 0;
  }
  @media (max-width: 440px) {
    right: -8px;
  }
}
</style>
