  <template>
  <main class="main main-course">
    <transition name="component-fade" mode="out-in" @after-enter="showFullDescription">
      <section v-if="!loading" class="section section-course">
        <div class="content">
          <Breadcrumbs
            :breadcrumbList="[
            {name: 'Курсы', link: '/'},
            {name: course.name}
          ]"
          />
        </div>
        <div class="course">
          <div class="course__banner" :style="{'background-image': courseImage}"></div>
          <div class="content">
            <div class="course__content">
              <div class="section-pretitle">Курс</div>
              <h2 class="section-title">{{ course.name }}</h2>
              <div class="section-text">
                <p>
                  {{ course.description }}
                </p>
              </div>
              <div class="section-text">
                <div id="editorjs"></div>
              </div>
            </div>
            <CertificateLink :course="course"/>
            <RcCourseProgressLinear
              v-if="isCourseStarted"
              :activity="course"
              class="mb-12"
            />
            <PartnersLogos v-if="isItAContestCourseId"/>
            <Group
              v-for="(group, index) in groups"
              :key="index"
              :group="group"
            />
          </div>
        </div>
      </section>
      <Loader v-else/>
    </transition>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Program from '@/api/program';
import deviceMixin from '@/mixins/device.mixin';
import courseBanner from '@/assets/images/courses/course__banner-1.jpg';
import Group from '@/components/course/group/Group.vue';
import Loader from '@/components/Loader.vue';
import EditorJS from '@editorjs/editorjs';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import CertificateLink from '@/components/buttons/CertificateLink.vue';
import PartnersLogos from '@/components/PartnersLogos.vue';
import toastMixin from '@/mixins/toast.mixin';
import RcCourseProgressLinear from '@/components/rc/RcCourseProgressLinear.vue';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

function findContestTasks(children, contestTasksIdList) {
  if (!Array.isArray(children)) {
    return [];
  }

  return children.reduce((currentList, value) => {
    const contestTasks = [];
    const { children: localChildren, activity } = value;

    if (contestTasksIdList.includes(activity?._id)) {
      contestTasks.push(activity);
    }
    if (localChildren?.length > 0) {
      contestTasks.push(...findContestTasks(value.children, contestTasksIdList));
    }

    return [...currentList, ...contestTasks];
  }, []);
}

export default {
  name: 'Course',

  components: {
    RcCourseProgressLinear,
    CertificateLink,
    Group,
    Breadcrumbs,
    Loader,
    PartnersLogos,
  },

  mixins: [deviceMixin, toastMixin],

  data() {
    return {
      course: null,
      groups: null,
      materials: null,
      loading: true,
    };
  },

  computed: {
    ...mapGetters('viewer', ['isItAContestCourseId', 'contestTasksIdList']),

    courseImage() {
      let image = courseBanner;
      if (this.course?.meta?.imageHighres) {
        image = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? this.course.meta.imageHighres
            : this.$device.isTablet
              ? this.course.meta.imageMidres
              : this.course.meta.imageLowres
        }`;
      }

      return `url(${image})`;
    },
    isCourseStarted() {
      return this.course?.statistic?.status > ACTIVITY_STATUSES.notStarted;
    },
    isCourseCompleted() {
      return this.course?.statistic?.status === ACTIVITY_STATUSES.done;
    },
    isContestRejected() {
      return (
        this.isCourseCompleted
        && this.contestTasksByCourse.some(
          (task) => task?.statistic?.status === ACTIVITY_STATUSES.rejected,
        )
      );
    },
    isContestCompleted() {
      return (
        this.isCourseCompleted
        && !this.contestTasksByCourse.some(
          (task) => task?.statistic?.status !== ACTIVITY_STATUSES.done,
        )
      );
    },
    contestTasksByCourse() {
      return findContestTasks(this.groups, this.contestTasksIdList);
    },
  },
  async created() {
    this.loading = true;
    await this.getProgram();
    this.loading = false;
    this.showToast();
  },

  methods: {
    async getProgram() {
      try {
        const {
          id,
          programId,
        } = this.$route.params;
        const response = await Program.getCourse(id, programId);
        this.course = response.data.activity;
        this.groups = response.data.children?.filter((activity) => activity.activity.type === 'group');
      } catch (e) {
        console.log('e', e);
      }
    },

    showFullDescription() {
      if (this.course.full_description) {
        const editorConfig = JSON.parse(this.course.full_description);
        // eslint-disable-next-line no-unused-vars
        const editor = new EditorJS({
          holder: 'editorjs',
          tools: {
            list: {
              class: List,
            },
            quote: {
              class: Quote,
            },
          },
          readOnly: true,
          logLevel: 'ERROR',
          data: editorConfig,
        });
      }
    },

    showToast() {
      const option = {
        message: '',
        background: '',
      };
      if (this.isCourseCompleted) {
        option.message = 'Поздравляю, вы прошли курс!';
        option.background = '#7EBF88';
      }

      if (this.isItAContestCourseId && this.isCourseStarted) {
        if (this.isContestCompleted) {
          option.message = 'Поздравляем! Вы прошли в следующий этап конкурса. О дальнейших шагах сообщим дополнительно на почту.';
          option.background = '#7EBF88';
        }

        if (this.isContestRejected) {
          option.message = 'К сожалению, вы не прошли в заочный этап конкурса. Будем рады вашему участию в конкурсе следующем году, а также в других проектах Академии! До новых встреч!';
          option.background = '#DB1C1C';
        }
      }

      if (option.message) {
        this.showMessage(option);
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/course.less";
</style>

<style lang="scss" scoped>
.lera-certificate-link--exist {
  margin-bottom: 24px;
}
</style>

<style lang="scss" scoped>
.section-text {
  color: unset;
}

.ce-stub {
  display: none;
}

.ce-block--selected {
  .ce-block__content {
    background: initial;
  }
}

.ce-inline-toolbar {
  &.ce-inline-toolbar--showed {
    color: initial;
  }
}

.ce-toolbar__content {
  max-width: none;
}
#editorjs::v-deep {

  .codex-editor {
    &__redactor {
      padding-bottom: 0 !important;
    }

    .ce-block {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &__content {
        margin: 0;
        max-width: unset;

        .ce-paragraph {
          padding: 0;
          overflow-wrap: break-word;
          line-height: 160%;
          word-break: break-word;
        }

        .ce-header {
          padding: 0;
          word-break: break-word;
        }

        h1.ce-header {
          word-break: break-word;
        }

        h2.ce-header {
          word-break: break-word;
        }

        h3.ce-header {
          word-break: break-word;
        }

        h4.ce-header {
          word-break: break-word;
        }

        h5.ce-header {
          word-break: break-word;
        }

        .cdx-list {
          margin-left: 1.5rem;
          padding: 0;
          color: #868686;

          &__item {
            padding: 0;
            word-break: break-word;
          }
        }

        .ce-rawtool,
        .ce-code {
          padding: 0;

          .ce-rawtool__textarea,
          .ce-code__textarea {
            min-height: max-content;
            border-radius: 0;
          }
        }

        .image-tool {
          &__caption {
            border: unset;
            padding: 0;
            margin-top: 0.5rem;
            word-break: break-word;
          }
        }

        .cdx-quote {
          padding: 0;

          &__text {
            position: relative;
            min-height: unset;
            padding: 0 0 0 2.25rem;
            margin-bottom: 0.5rem;
            border: unset;
            word-break: break-word;

            &:before {
              display: none;
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 1.25rem;
              height: 100%;
            }
          }

          &__caption {
            padding: 0;
            border: unset;
            word-break: break-word;
          }
        }
      }
    }
  }

  .cdx-button {
    transition: 0.25s;

    &:hover {
      background: #273344;
      box-shadow: 0 24px 38px rgb(0 0 0 / 14%), 0 9px 46px rgb(0 0 0 / 12%),
      0 11px 15px rgb(0 0 0 / 20%);
    }
  }

  .image-tool {
    --bg-color: #1b212800;
    --front-color: #faa433;
    --border-color: #ffffff26;
  }
}

.cdx-list {
  margin: 0;
  padding-left: 40px;
  outline: none;

  &__item {
    padding: 5.5px 0 5.5px 3px;
    line-height: 1.6em;
  }

  &--unordered {
    list-style: disc;
  }

  &--ordered {
    list-style: decimal;
  }

  &-settings {
    display: flex;

    .cdx-settings-button {
      width: 50%;
    }
  }
}

.cdx-quote-icon svg {
  transform: rotate(180deg);
}

.cdx-quote {
  margin: 0;
}

.cdx-quote__text {
  min-height: 158px;
  margin-bottom: 10px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.cdx-quote__caption {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.cdx-quote [contentEditable='true'][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-quote [contentEditable='true'][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-quote [contentEditable='true'][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-quote-settings {
  display: flex;
}

.cdx-quote-settings .cdx-settings-button {
  width: 50%;
}

</style>
