<template>
  <a
      :href="urlToFile"
      target="_blank"
      download
      class="uploaded-link"
      :title="fileName"
  >
    {{ fileName }}
  </a>
</template>

<script>
export default {
  name: 'LinkToFile',

  props: {
    fileName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },

  computed: {
    urlToFile() {
      if (this.url) {
        return `https://${this.url}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>
</style>
