<template>
  <div
    class="lera-certificate-link"
    :class="certificateHref ? 'lera-certificate-link--exist' : ''"
  >
    <template v-if="certificateHref">
      <a
        v-if="isCertificateLinkActive"
        :href="certificateHref"
        download="certificate.png"
        class="button button--hollow button--certificate"
      >
        <img
          src="@/assets/images/icons/icon__download--red.svg"
          alt="Вперёд"
        >Скачать сертификат</a>
      <button
        v-else
        class="button button--hollow button--disabled button--certificate"
      >
        <img
          src="@/assets/images/icons/icon__download--grey.svg"
          alt="Вперёд"
        >Скачать сертификат
      </button>
    </template>
    <template v-if="!!certificateDataComputed">
      <lera-certificate-generator
        v-show="false"
        :value="certificateDataComputed"
        @canvas-updated="updateCertificateHref"
      />
    </template>
  </div>

</template>

<script>
import programApi from '@/api/program';
import { mapState } from 'vuex';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'CertificateLink',

  props: ['course'],

  data: () => ({
    certificateData: null,
    certificateHref: '',
  }),

  computed: {
    ...mapState('user', [
      'user',
    ]),

    score() {
      const maxScore = this.course?.max_score || 0;
      const currentScore = this.course?.statistic.score || 0;
      return `${currentScore}/${maxScore}`;
    },

    username() {
      return [this.user?.first_name || '', this.user?.last_name || ''].join(' ');
    },

    /**
     * Replace sample text with actual data
     */
    certificateDataComputed() {
      if (!this.certificateData) {
        return null;
      }

      const result = { ...this.certificateData };
      const layerData = result?.children.find((el) => el.className === 'Layer');
      if (!layerData) {
        return null;
      }

      const certDataMap = [
        {
          className: 'Text',
          name: 'fio',
          text: this.username,
        },
        {
          className: 'Text',
          name: 'score',
          text: this.score,
        },
      ];

      certDataMap.forEach((certData) => {
        if (!certData?.text) {
          return;
        }

        // eslint-disable-next-line
        const certDataMatch = layerData?.children.find((el) => el.className === certData.className && el.attrs.name === certData.name);
        if (!certDataMatch) {
          return;
        }

        certDataMatch.attrs.text = certData.text;
      });

      return result;
    },

    isCertificateLinkActive() {
      return !!this.certificateHref && (this.course?.statistic?.status === ACTIVITY_STATUSES.done);
    },
  },

  mounted() {
    this.fetchCertificateData();
  },

  methods: {
    updateCertificateHref(base64String) {
      this.certificateHref = base64String;
    },
    async fetchCertificateData() {
      const activityId = this.course._id;
      if (!activityId) {
        return;
      }

      try {
        const result = await programApi.getCertificate(activityId);
        this.certificateData = result?.data?.config || null;
      } catch (e) {
        this.certificateData = null;
      }
    },
  },
};
</script>

<style scoped lang="less">
.button--certificate {
  width: 240px;
  height: 47px;
  font-size: 16px;
  padding: 14px 21px
}
</style>
