<template>
  <div class="page">
    <Header />
    <v-main>
      <transition name="component-fade" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue';

export default {
  name: 'AuthLayout',

  components: {
    Header,
  },
};
</script>

<style scoped>

</style>
