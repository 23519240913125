<template>
  <div class="login__panel">
    <div class="login__content">
      <div class="block-title">Спасибо за регистрацию</div>
    </div>
    <div class="buttons">
      <router-link to="/" class="button">Продолжить</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessForm',
};
</script>

<style scoped>

</style>
