<template>
  <img
      src="@/assets/images/icons/icon__documents-1.svg"
      alt="Иконка"
      class="documents-card__icon"
  >
</template>

<script>
export default {
  name: 'VolunteeringIcon',
};
</script>

<style scoped>

</style>
