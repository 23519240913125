import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  props: {
    activity: {
      type: Object,
      default: undefined,
    },
    progress: {
      type: [String, Number],
      default: undefined,
    },
  },
  computed: {
    statusTextComputed() {
      if (!this.activity?.statistic) return 'Статус не определен';
      switch (this.activity.statistic.status) {
        case ACTIVITY_STATUSES.notStarted:
          return 'Не начат';
        case ACTIVITY_STATUSES.doing:
          return 'Начат';
        case ACTIVITY_STATUSES.rejected:
          return 'Не пройден';
        case ACTIVITY_STATUSES.done:
          return 'Пройден';
        default:
          return 'Статус не определен';
      }
    },
    maxScoreComputed() {
      return this.activity?.max_score || 0;
    },
    currentScoreComputed() {
      return this.activity?.statistic?.score || 0;
    },
    progressComputed() {
      // progress had been provided
      if (this.progress) {
        return Number(this.progress);
      }

      // it is not an activity
      if (!this.activity) {
        return 0;
      }

      const scored = this.activity.statistic.score || 0;
      const maxScore = this.activity?.max_score;
      const isStatusNotStarted = [
        ACTIVITY_STATUSES.notStarted,
      ].includes(this.activity.statistic.status);
      const isStatusDone = [
        ACTIVITY_STATUSES.rejected,
        ACTIVITY_STATUSES.done,
      ].includes(this.activity.statistic.status);

      // nothing to show yet
      if (isStatusNotStarted) {
        return 0;
      }

      // it is all done
      if (isStatusDone) {
        return 100;
      }

      // probably they are trying
      if (!maxScore) {
        return 50;
      }

      return Math.round((scored / maxScore) * 100);
    },
  },
};
