<template>
  <section class="section section-banner">
    <div class="content">
      <div class="banner">
        <div class="banner__pic"></div>
        <div class="banner__content">
          <div class="section-header">
            <h1 class="section-title">Академия <br> Красного Креста</h1>
            <div class="section-text">
              <p>Академия сохраняет и передаёт 100-летний опыт Красного Креста и
                РНИМУ имени Н. И. Пирогова: как спасать жизни, сохранять здоровье
                и обеспечивать безопасность людей. Мы можем этому научить.</p>
            </div>
          </div>
          <div v-if="!isAuthenticated" class="buttons">
            <v-btn
              to="/auth"
              color="red"
              dark
              large
              :block="$vuetify.breakpoint.mobile"
            >
              Войти на платформу
            </v-btn>
            <v-btn
              color="red"
              dark
              large
              outlined
              :block="$vuetify.breakpoint.mobile"
              @click="scrollTo('section-whom')"
            >
              Об академии
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SectionBanner',

  computed: {
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
    }),
  },

  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
