export default {
  light: {
    primary: '#DB1C1C',
    primary02: '#EF2727',
    primary03: '#C71C1C',
    secondary: '#EE3F3F',
    secondary02: '#E23B3B',
    secondary03: '#A41717',
    typography: '#282828',
    typography02: '#868686',
    typography03: '#ACACAC',
    success: '#7EBF88',
    warning: '#A3A3A3',
    error: '#DB1C1C',
    anchor: 'grey',
    background: '#E5E5E5',
  },
};
