<template>
  <div>
    <h3 class="block-pretitle">Вопрос {{questionNumber}}/{{questionsQuantity}}</h3>
    <h3 class="block-subtitle">{{ questionText }}</h3>
  </div>
</template>

<script>
export default {
  name: 'QuizHeader',

  props: {
    questionNumber: {
      type: Number,
      required: true,
    },
    questionsQuantity: {
      type: Number,
      required: true,
    },
    questionText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
