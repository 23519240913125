<template>
  <div class="card">
    <h3>Партнеры конкурса</h3>
    <div class="logos-list">
    <div v-for="logo in logosList" :key="logo.src" class="logo-item">
      <a :href="logo.href">
        <img :src="logo.src">
      </a>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    //
  }),
  computed: {
    logosList() {
      const result = [
        {
          src: require('@/assets/images/logos/minzdrav.png'),
          href: 'https://minzdrav.gov.ru/ru',
        },
        {
          src: require('@/assets/images/logos/fadm.png'),
          href: 'https://fadm.gov.ru/',
        },
        {
          src: require('@/assets/images/logos/fmba.png'),
          href: 'https://fmba.gov.ru/',
        },
        {
          src: require('@/assets/images/logos/mednet.png'),
          href: 'https://mednet.ru/',
        },
      ];
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 42px 44px;
  margin-bottom: 42px;
  background: #fff;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.logos-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.logo-item {
  margin-top: 15px;
}
</style>
