<template>
  <component :is="pageName"></component>
</template>

<script>
import MainGuest from '@/components/mainGuest/MainGuest.vue';
import CourseCatalog from '@/components/rc/RcCourseCatalog.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Main',

  components: {
    MainGuest,
    CourseCatalog,
  },

  computed: {
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
    }),

    pageName() {
      if (this.isAuthenticated) return 'CourseCatalog';
      return 'MainGuest';
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/main.less";
</style>
