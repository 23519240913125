<template>
  <div class="hesk__container">
    <div class="hesk__header">
      <h2>Написать в поддержку</h2>
      <v-btn color="red" dark small rounded @click="closeForm">
        <img src="@/assets/images/icons/icon__close--white.svg" alt="Close" />
      </v-btn>
    </div>

    <transition name="hesk-fade" mode="out-in">
      <div v-if="!selected" key="options" class="hesk__form">
        <h4>Выберите тему обращения</h4>

        <div class="hesk__options">
          <v-btn
            v-for="opt in options"
            :key="opt.id"
            outlined
            color="red"
            @click="selected = opt.id"
          >
            {{ opt.text }}</v-btn
          >
        </div>
      </div>
      <div v-else key="form" class="hesk__form">
        <h4>{{ selectedOption.text }}</h4>

        <p>{{ selectedOption.description }}</p>

        <div class="hesk__textarea">
          <v-textarea
            v-model="message"
            class="hide-scrollbar"
            :class="{'field-error': $v.message.$dirty && !$v.message.required}"
            outlined
            placeholder="Введите текст"
            variant="outlined"
          ></v-textarea>
          <span v-if="$v.message.$dirty && !$v.message.required" class="field__error">
            Заполните поле
          </span>
        </div>

        <div v-if="!isAuthenticated">
          <h5>Ваш e-mail</h5>
          <label class="field">
            <input
              v-model.trim="unauthEmail"
              :class="{ 'field__value--error': $v.unauthEmail.$dirty && $v.unauthEmail.$invalid }"
              type="email"
              class="field__value"
              placeholder="Электронная почта"
              autofocus
            />
            <span v-if="$v.unauthEmail.$dirty && !$v.unauthEmail.required" class="field__error">
              Заполните поле
            </span>
            <span
              v-else-if="$v.unauthEmail.$dirty && !$v.unauthEmail.emailRule"
              class="field__error"
            >
              Некорректный E-mail
            </span>
          </label>
        </div>

        <div class="hesk__action">
          <v-btn text color="black" large dark @click="back">Назад</v-btn>
          <v-btn
            :loading="loading"
            :class="{ 'button--disabled': isActionDisabled }"
            color="red"
            large
            dark
            @click="submit"
            >Отправить</v-btn
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { email as emailRule, required } from 'vuelidate/lib/validators';
import HeskService from '@/api/hesk';
import toastMixin from '@/mixins/toast.mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'HeskForm',
  mixins: [toastMixin],
  props: {
    closeForm: {
      type: Function,
      reqruied: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    selected: null,
    message: '',
    unauthEmail: '',
  }),
  validations: {
    message: {
      required,
    },
    unauthEmail: {
      required,
      emailRule,
    },
  },
  computed: {
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
      authEmail: 'email',
      fullName: 'fullName',
    }),
    selectedOption() {
      return this.options.find((i) => i.id === this.selected);
    },
    isActionDisabled() {
      if (!this.isAuthenticated) {
        return !(this.message.length && this.unauthEmail.length);
      }
      return !this.message.length;
    },
  },
  watch: {
    isAuthenticated() {
      this.back();
      this.$emit('close');
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.isAuthenticated && this.$v.unauthEmail.$invalid) {
        return;
      }
      if (this.$v.message.$invalid) {
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      await HeskService.submitForm({
        message: this.message,
        category: this.selectedOption.id,
        name: this.isAuthenticated ? this.fullName : 'guest user',
        email: this.isAuthenticated ? this.authEmail.toLowerCase() : this.unauthEmail.toLowerCase(),
        subject: 'no subject',
      })
        .then(() => {
          this.closeForm();
          this.showMessage({
            title: 'Обращение успешно отправлено!',
            message:
              'Мы рассмотрим ваше сообщение в ближайшее время и свяжемся с вами по электронной почте. Спасибо за обращение!',
            background: '#7EBF88',
          });
        })
        .catch(() => this.showError({
          title: 'Обращение не отправлено',
          message: 'При отправке возникла ошибка. Попробуйте ещё раз',
        }))
        .finally(() => (this.loading = false));
    },
    back() {
      this.selected = null;
      this.message = '';
      this.$v.$reset();
    },
  },
};
</script>
