<template>
  <img
    src="@/assets/images/icons/icon__theme-video.svg"
    alt="Видео"
  >
</template>

<script>
export default {
  name: 'Video',
};
</script>

<style scoped>

</style>
