<template>
  <v-row
    no-gutters
    :justify="justify"
    align="center"
  >
    <v-card
      v-for="link in linksList"
      :key="link.name"
      :href="link.href"
      flat
      color="transparent"
      :ripple="false"
      class="mx-1"
      rounded="0"
      height="20"
      width="20"
    >
      <v-img
        target="_blank"
        :src="link.src"
        :alt="link.name"
        contain
      />
    </v-card>
  </v-row>
</template>

<script>
import constantsMixin from '@/mixins/constantsLinks.mixin';

export default {
  name: 'SocialLinks',

  mixins: [constantsMixin],
  props: {
    justify: {
      type: String,
      default: 'center',
    },
  },
  computed: {
    linksList() {
      const result = [
        {
          href: this.links.ok,
          src: require('@/assets/images/icons/icon__social-ok.png'),
          name: 'Одноклассники',
        },
        {
          href: this.links.dzen,
          src: require('@/assets/images/icons/icon__social-dzen.png'),
          name: 'Яндекс.Дзен',
        },
        {
          href: this.links.tg,
          src: require('@/assets/images/icons/icon__social-telegram.png'),
          name: 'Телеграм',
        },
        {
          href: this.links.vk,
          src: require('@/assets/images/icons/icon__social-vk.png'),
          name: 'ВК',
        },
      ];
      return result;
    },
  },
};
</script>

<style scoped>

</style>
