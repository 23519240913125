<template>
  <v-card
    :max-width="maxWidth"
    min-height="105"
    class="py-1 pl-6 pr-1"
    elevation="5"
  >
    <v-row
      no-gutters
      align="start"
    >
      <v-list-item-avatar
        color="red"
        height="68"
        width="68"
        class="mt-4"
      >
        <v-img
          src="@/assets/images/icons/icon__user--white.svg"
          height="38"
          contain
        />
      </v-list-item-avatar>

      <v-col
        height="100%"
        width="100%"
        class="ml-4 mt-5 mb-5"
      >
        <v-scroll-x-transition hide-on-leave leave-absolute>

          <v-row v-if="editMode" key="edit">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="newFirstName"
                label="Имя"
                hide-details
                @keyup.enter="save"
                @keyup.esc="reset"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="newLastName"
                label="Фамилия"
                hide-details
                @keyup.enter="save"
                @keyup.esc="reset"
              />
            </v-col>
            <!--
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newEmail"
                label="Email"
                hide-details
                @keyup.enter="save"
                @keyup.esc="reset"
              />
            </v-col>
            -->
          </v-row>

        <div v-else key="read">
          <v-row no-gutters>
            <p class="font-weight-bold text-body-1 mb-0">{{ fullName }}</p>
          </v-row>
          <v-row no-gutters>
            <p class="red--text mb-0">{{ email }}</p>
          </v-row>
        </div>

        </v-scroll-x-transition>
      </v-col>

      <v-scale-transition hide-on-leave leave-absolute>
      <div v-if="editMode" key="edit">
      <v-btn
        icon
        @click="save"
      >
        <v-icon color="red">mdi-check</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="reset"
      >
        <v-icon color="red">mdi-close</v-icon>
      </v-btn>
      </div>

      <div v-else key="read">
      <v-btn
        icon
        @click="editMode = !editMode"
      >
        <v-icon color="red">mdi-square-edit-outline</v-icon>
      </v-btn>
      </div>
      </v-scale-transition>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RcMyProfile',

  props: {
    maxWidth: {
      type: [String, Number],
      default: undefined,
    },
  },

  data: () => ({
    editMode: false,
    newFirstName: '',
    newLastName: '',
    newEmail: '',
  }),
  computed: {
    ...mapGetters('user', [
      'firstName',
      'lastName',
      'fullName',
      'email',
    ]),
  },
  watch: {
    fullname() {
      this.syncUserData();
    },
    email() {
      this.syncUserData();
    },
  },
  mounted() {
    this.syncUserData();
  },
  methods: {
    ...mapActions('user', [
      'saveUserInfo',
    ]),
    syncUserData() {
      this.newFirstName = this.firstName;
      this.newLastName = this.lastName;
      this.newEmail = this.email;
    },
    async save() {
      this.editMode = false;

      const firstName = this.newFirstName;
      const lastName = this.newLastName;
      const email = this.newEmail;

      await this.saveUserInfo({
        firstName,
        lastName,
        email,
      });
      this.syncUserData();
    },
    reset() {
      this.editMode = false;
      this.syncUserData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
