<template>
  <div
    class="b-form-tag tag"
    :class="{
      'more-button': variant === 'secondary',
      'user-select-none': !isEditable,
      'tag--inverse': isEditable && editing,
      selected: isSelected,
    }"
    @click="handleClick"
  >
    <div v-if="!!this.$slots['prefixIcon']" class="prefixIcon" @click.stop="$emit('clickPrefix')">
      <slot name="prefixIcon" />
    </div>
    <span ref="badge" :contenteditable="isEditable" @keypress="filterInput" @blur="onBlur">
      {{ title }}
    </span>
    <div v-if="!!this.$slots['suffixIcon']" class="suffixIcon" @click.stop="$emit('clickSuffix')">
      <slot name="suffixIcon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      default: null,
    },
    update: {
      type: Function,
      default: () => Promise.resolve,
    },
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    selectedTagsId: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editing: false,
  }),
  computed: {
    isSelected() {
      return this.selectedTagsId.includes(this.tag?.id);
    },
  },
  methods: {
    handleClick(e) {
      if (e.target !== this.$refs.badge) {
        this.$refs.badge.blur();
      } else {
        this.editing = true;
      }
      this.$emit('click', this.tag?.id);
    },
    filterInput(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
      if (event.keyCode === 32 && !this.$refs.badge.innerText.trim()) {
        event.preventDefault();
      }
      if (this.$refs.badge.innerText.length > 63) {
        event.preventDefault();
      }
    },
    onBlur(event) {
      this.editing = false;
      const newValue = event.target.innerText.trim();
      this.$refs.badge.innerText = newValue;
      if (newValue !== this.title && this.tag) {
        this.update({ ...this.tag, name: newValue }).catch(() => {
          this.$nextTick(() => {
            this.$refs.badge.innerText = this.tag.name;
          });
        });
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/tag.less";
</style>
