<template>
  <div class="login__panel">
    <div class="login__content">
      <div class="block-title">Сброс пароля</div>
      <div class="login__text">Мы отправили вам на почту ссылку для сброса пароля</div>
    </div>
    <div class="buttons">
      <router-link to="/auth" class="button">Продолжить</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessForm',
};
</script>

<style scoped>

</style>
