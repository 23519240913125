<template>
  <main class="main main-login fill-height">
    <div v-scrollanimation="{className: 'login--animated'}" class="login">
      <div v-if="!isSuccessfullySend" class="content">
        <ForgotPasswordCard
          :error="error"
          :loading="loading"
          @submit="resetPassword"
        />
      </div>
      <div v-else class="content">
        <SuccessForm/>
      </div>
    </div>
  </main>
</template>

<script>
import authorization from '@/api/authorization';
import SuccessForm from '@/components/authorization/forgotPassword/SuccessForm.vue';
import ForgotPasswordCard from '@/components/authorization/forgotPassword/ForgotPasswordCard.vue';

export default {
  name: 'ForgotPassword',

  components: {
    ForgotPasswordCard,
    SuccessForm,
  },

  data() {
    return {
      loading: false,
      error: '',
      isSuccessfullySend: false,
    };
  },

  methods: {
    async resetPassword(email) {
      this.error = '';
      this.loading = true;
      try {
        await authorization.resetPassword(email);
        this.isSuccessfullySend = true;
      } catch (error) {
        const status = error.response?.status;

        if (status === 404 || status === 400) {
          this.error = 'Почта не найдена';
        } else if (status === 401) {
          this.error = 'Почта не подтверждена';
        } else {
          this.error = 'Что-то пошло не так...';
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/login.less";
</style>
