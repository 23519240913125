<template>
  <main class="main main-testing">

    <section class="section section-testing">
      <div class="testing">
        <div class="testing__header">
          <router-link :to="courseUrl" class="link link--back">Вернуться к курсу</router-link>
          <div class="list-open" @click="isVisibleMaterialList = true"></div>
        </div>
        <div class="testing__body">
          <MaterialList
              v-if="materials"
              :isVisible="isVisibleMaterialList"
              :materials="materials"
              @hide="isVisibleMaterialList = false"
          />
          <div v-if="selectedMaterial" class="testing__content">
            <div class="section-header">
              <h2 class="block-title">{{ selectedMaterial.name }}</h2>
              <div class="section-text">
                <lera-markdown-reader :value="description" />
              </div>
            </div>
            <div v-if="getIsLoaderShown">
              <div class="viewer-loader">
                <Loader/>
              </div>
            </div>
            <ViewerPlayer :activity="selectedMaterial"/>
            <v-row no-gutters>
            <v-btn v-if="prevUrl" :to="prevUrl" text color="red" exact class="text-body-1">
              <v-icon small class="mr-1">mdi-arrow-left</v-icon>
              Назад
            </v-btn>
            <v-spacer />
            <v-btn v-if="nextUrl" :to="nextUrl" text color="red" exact class="text-body-1">
              Далее
              <v-icon small class="ml-1">mdi-arrow-right</v-icon>
            </v-btn>
            </v-row>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import deviceMixin from '@/mixins/device.mixin';
import MaterialList from '@/components/viewer/MaterialList.vue';
import ViewerPlayer from '@/components/viewer/ViewerPlayer.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Viewer',

  components: {
    MaterialList,
    ViewerPlayer,
    Loader,
  },

  mixins: [deviceMixin],

  data() {
    return {
      isVisibleMaterialList: true,
      selectedMaterial: null,
    };
  },

  beforeRouteLeave(to, from, next) {
    this.fetchCoursesList();
    next();
  },

  computed: {
    ...mapState('viewer', {
      materials: 'materials',
    }),

    ...mapGetters('loader', {
      getIsLoaderShown: 'getIsLoaderShown',
    }),
    ...mapGetters('viewer', [
      'prevActivityId',
      'nextActivityId',
    ]),

    prevUrl() {
      if (!this.prevActivityId) {
        return null;
      }
      const {
        parentId,
        courseId,
        programId,
      } = this.$route.params;
      return `/viewer/${parentId}/${programId}/${courseId}?activityId=${this.prevActivityId}`;
    },

    nextUrl() {
      if (!this.nextActivityId) {
        return null;
      }
      const {
        parentId,
        courseId,
        programId,
      } = this.$route.params;
      return `/viewer/${parentId}/${programId}/${courseId}?activityId=${this.nextActivityId}`;
    },

    courseUrl() {
      const {
        courseId,
        programId,
      } = this.$route.params;
      return `/course-user/${courseId}/${programId}`;
    },

    description() {
      if (this.selectedMaterial.type === 'task') return this.selectedMaterial.meta.text;
      return this.selectedMaterial.description;
    },
  },

  watch: {
    $route() {
      this.selectMaterial();
    },
  },

  async created() {
    this.isVisibleMaterialList = this.$device.isDesktop;
    const { params } = this.$route;
    await this.getMaterials(params);
    this.selectMaterial();
  },

  methods: {
    ...mapActions('viewer', {
      getMaterials: 'getMaterials',
    }),
    ...mapActions('courses', [
      'fetchCoursesList',
    ]),

    selectMaterial() {
      this.selectedMaterial = null;
      setTimeout(() => {
        const currentActivityId = this.$route.query.activityId;
        if (currentActivityId) {
          this.selectedMaterial = this.materials
            .find((material) => material._id === currentActivityId);
        } else {
          const [selectedMaterial] = this.materials;
          this.selectedMaterial = selectedMaterial;
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/testing.less";
</style>

<style scoped>
.viewer-loader {
  margin-top: 80px;
}
.list {
  z-index: 1;
}
.testing__content {
  z-index: 0;
}
</style>
