<!-- eslint-disable max-len -->
<template>
  <img
    src="@/assets/images/icons/icon__material-presentation--x2.png"
    alt="Презентация"
  >
</template>

<script>
export default {
  name: 'PptxIcon',
};
</script>
