const documents = [
  {
    title: 'Политика ККиКП',
    files: [
      {
        name: 'Конституция МФОККиКП',
        url: '/documents/Политика ККиКП/Конституция МФОККиКП.pdf',
      },
      {
        name: 'Политика развития ККиКП',
        url: '/documents/Политика ККиКП/ПОЛИТИКА РАЗВИТИЯ КРАСНОГО КРЕСТА И КРАСНОГО ПОЛУМЕСЯЦА.pdf',
      },
      {
        name: 'Молодежная политика',
        url: '/documents/Политика ККиКП/Молодежная политика.pdf',
      },
      {
        name: 'Политика по сбору средств',
        url: '/documents/Политика ККиКП/Политика по  сбору средств.pdf',
      },
      {
        name: 'Политика продовольственной безопасности',
        url: '/documents/Политика ККиКП/Политика продовольственной безопасности.pdf',
      },
      {
        name: 'Гендерная политика',
        url: '/documents/Политика ККиКП/гендерная политика.pdf',
      },
      {
        name: 'Глобальные перспективы',
        url: '/documents/Политика ККиКП/глобальные перспективы.pdf',
      },
      {
        name: 'Политика по развитию сотрудничества',
        url: '/documents/Политика ККиКП/Политика по развитию сотрудничества.pdf',
      },
    ],
  },
  {
    title: 'Здравоохранение и уход',
    files: [
      {
        name: 'COVID-19 и восстановление',
        url: '/documents/Здравоохранение и уход/COVID и восстановление.pdf',
      },
      {
        name: 'Политика в области ВИЧ/СПИДа',
        url: '/documents/Здравоохранение и уход/Политика в области ВИЧ.pdf',
      },
      {
        name: 'Политика в области здравоохранения',
        url: '/documents/Здравоохранение и уход/Политика в области здравоохранения.pdf',
      },
      {
        name: 'Политика оказания первой помощи',
        url: '/documents/Здравоохранение и уход/Политика оказания первой помощи.pdf',
      },
      {
        name: 'Развитие надежных систем крови',
        url: '/documents/Здравоохранение и уход/Развитие надежных систем крови.pdf',
      },
      {
        name: 'Отчет по адвокации закона о первой помощи',
        url: '/documents/Здравоохранение и уход/отчет по адвокации закона о первой помощи.pdf',
      },
      {
        name: 'Проблемы психического здоровья',
        url: '/documents/Здравоохранение и уход/проблемы психического здоровья.pdf',
      },
      {
        name: 'Соображения ВОЗ по корректировке мер общественного здравоохранения и социальных мер в контексте COVID-19',
        url: '/documents/Здравоохранение и уход/Соображения ВОЗ по корректировке мер общественного здравоохранения и социальных мер в контексте COVID-19.pdf',
      },
    ],
  },
  {
    title: 'Волонтерство',
    files: [
      {
        name: 'Волонтеры',
        url: '/documents/Волонтерство/Волонтеры.pdf',
      },
    ],
  },
  {
    title: 'Миграция',
    files: [
      {
        name: 'Миграционная политика',
        url: '/documents/Миграция/Миграционная политика.pdf',
      },
      {
        name: 'Новый диалог о миграции',
        url: '/documents/Миграция/Новый диалог о миграции.pdf',
      },
      {
        name: 'Политика социального обеспечения',
        url: '/documents/Миграция/Политика социального обеспечения.pdf',
      },
      {
        name: 'Домашние работники',
        url: '/documents/Миграция/домашние работники.pdf',
      },
      {
        name: 'Мигранты, незаконно переправляемые по морю',
        url: '/documents/Миграция/Мигранты, незаконно переправляемые по морю.pdf',
      },
      {
        name: 'Миграция в Европейском регионе',
        url: '/documents/Миграция/Миграция в Европейском регионе.pdf',
      },
    ],
  },
  {
    title: 'Чрезвычайные ситуации',
    files: [
      {
        name: 'Меры реагирования на пандемию',
        url: '/documents/Чрезвычайные ситуации/Меры реагирования на пандемию.pdf',
      },
      {
        name: 'Управление рисками',
        url: '/documents/Чрезвычайные ситуации/Управление рисками.pdf',
      },
      {
        name: 'Планы по преодолению климатического кризиса',
        url: '/documents/Чрезвычайные ситуации/Планы по преодолению климатического кризиса.pdf',
      },
      {
        name: 'Снижение риска бедствий: глобальное руководство по адвокации',
        url: '/documents/Чрезвычайные ситуации/Снижение риска бедствий глобальное руководство по адвокации.pdf',
      },
    ],
  },
];

export default documents;
