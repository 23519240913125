<template>
  <v-select
    v-model="selectedCityComputed"
    :options="suggestionsListComputed"
    :get-option-label="(option) => option.value"
    :get-option-key="(option) => option.data.kladr_id + option.value"
    :loading="queryPending"
    :clearable="false"
    @search="fetchOptions">
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes"></span>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" placeholder="Город" v-on="events" />
      <div class="field__label">Город</div>
    </template>
    <template #no-options="{}">
      Не найдено
    </template>
    Регион и город
  </v-select>
</template>

<script>
import vSelect from 'vue-select';
import {
  mapActions,
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';

export default {
  components: {
    vSelect,
  },

  data: () => ({
    searchTimeoutId: null,
  }),

  computed: {
    ...mapGetters('geolocation', [
      'suggestionsListComputed',
    ]),
    ...mapState('geolocation', [
      'query',
      'selectedCity',
      'queryPending',
    ]),

    selectedCityComputed: {
      get() {
        return this.selectedCity;
      },
      set(newValue) {
        this.setSelectedCity(newValue);
      },
    },

    queryComputed: {
      get() {
        return this.query || '';
      },
      set(newValue) {
        this.setQuery(newValue);
      },
    },
  },

  watch: {
    queryComputed() {
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }
      this.searchTimeoutId = setTimeout(() => {
        this.fetchSuggestions();
      }, 400);
    },
  },

  methods: {
    ...mapActions('geolocation', [
      'fetchSuggestions',
    ]),
    ...mapMutations('geolocation', [
      'setQuery',
      'setSelectedCity',
    ]),

    fetchOptions(search) {
      this.queryComputed = search;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
