<template>
  <main class="main main-login fill-height">

    <div v-scrollanimation="{className: 'login--animated'}" class="login">
      <div v-if="!isSuccessfullySend" class="content">
        <div class="login__panel">
          <div class="login__header">
            <AuthorizationTabs/>
            <div v-if="step === 1" class="login__description">
              Шаг 1. Обязательное для заполнения
            </div>
            <div v-if="step === 2" class="login__description">
              Шаг 2. Дополнительная информация
            </div>
          </div>

          <FirstStepForm
            v-if="step === 1"
            :loading="loading"
            :error="error"
            @submit="submitFirstForm"
          />

          <SecondStepForm
            v-if="step === 2"
            :loading="loading"
            :error="error"
            @submit="submitSecondForm"
          />
        </div>
      </div>
      <div v-else class="content">
        <SuccessForm/>
      </div>
    </div>

  </main>
</template>

<script>
import authorization from '@/api/authorization';
import FirstStepForm from '@/components/authorization/registration/FirstStepForm.vue';
import SecondStepForm from '@/components/authorization/registration/SecondStepForm.vue';
import SuccessForm from '@/components/authorization/registration/SuccessForm.vue';
import AuthorizationTabs from '@/components/authorization/AuthorizationTabs.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Registration',

  components: {
    FirstStepForm,
    SecondStepForm,
    SuccessForm,
    AuthorizationTabs,
  },

  data() {
    return {
      user: {},
      step: 1,
      isSuccessfullySend: false,
      loading: false,
      error: '',
    };
  },

  methods: {
    ...mapActions('user', [
      'onRegistrationSuccess',
    ]),
    async submitFirstForm(data) {
      try {
        this.error = '';
        this.loading = true;
        this.user = { ...this.user, ...data };
        await authorization.checkEmail(this.user.username);
        this.step += 1;
      } catch (error) {
        if (error.response?.status === 400) {
          this.error = 'Такая почта уже зарегистрирована';
        } else if (error.response?.status >= 400 && error.response?.status <= 500) {
          this.error = 'Что-то пошло не так';
        } else {
          this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },

    submitSecondForm(data) {
      this.user = { ...this.user, ...data };
      this.register();
    },

    async register() {
      try {
        this.error = '';
        this.loading = true;
        await authorization.signUp(this.user);
        await this.$store.dispatch('user/getUserInfo');
        this.isSuccessfullySend = true;

        // will send an email
        this.onRegistrationSuccess();
      } catch (error) {
        if (error.response?.status >= 400 && error.response?.status <= 500) {
          this.error = 'Что-то пошло не так';
        } else {
          this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/login.less";
</style>
