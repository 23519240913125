import { HTTP } from '@/api/default';

const heskUrl = process.env.VUE_APP_HESK_URL;

const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

const clearHeskCookies = () => {
  const cookies = document.cookie.split('; ');

  const heskCookies = cookies.filter((i) => i.includes('HESK' || 'hesk_myemail'));
  if (heskCookies.length) {
    // eslint-disable-next-line array-callback-return
    heskCookies.map((c) => {
      const heskCookieName = c.split('=')[0];
      setCookie(heskCookieName, '', {
        'max-age': -1,
      });
    });
  }
};

export default {
  // mock answer
  async fetchCategories() {
    const response = await HTTP({
      url: '/api/activity/hesk-categories',
      method: 'GET',
    });
    return response.data;
  },
  submitForm(form) {
    const payload = {
      priority: 'low', // optional, default is low/ mandatory
      hx: '3', // mandatory, antispam
      hy: '', // mandatory, antispam
      ...form,
    };

    const params = new URLSearchParams();
    Object.keys(payload).forEach((key) => {
      params.append(key, payload[key]);
    });

    clearHeskCookies();

    return fetch(`${heskUrl}/submit_ticket.php?submit=1`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: params,
      redirect: 'error',
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        return response.body;
      })
      .catch(() => {
        throw new Error('Failed');
      });
  },
};
