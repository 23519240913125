<template>
  <div class="documents-card">
    <div class="documents-card__icon-wrap">
      <component
          :is="documentCardIcon"
          class="list__icon"
      />
    </div>
    <h3 class="block-title">{{ title }}</h3>
    <div
        v-for="(file, index) in files"
        :key="index"
        class="documents-card__list"
    >
      <Document :file="file"/>
    </div>
  </div>
</template>

<script>
import Document from '@/components/documents/Document.vue';
import AdditionallyIcon from '@/components/documents/icons/AdditionallyIcon.vue';
import EmergencyIcon from '@/components/documents/icons/EmergencyIcon.vue';
import HealthcareIcon from '@/components/documents/icons/HealthcareIcon.vue';
import MigrationIcon from '@/components/documents/icons/MigrationIcon.vue';
import YouthPolicyIcon from '@/components/documents/icons/YouthPolicyIcon.vue';
import VolunteeringIcon from '@/components/documents/icons/VolunteeringIcon.vue';

export default {
  name: 'DocumentCard',

  components: {
    Document,
    AdditionallyIcon,
    EmergencyIcon,
    HealthcareIcon,
    MigrationIcon,
    YouthPolicyIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },

  computed: {
    documentCardIcon() {
      switch (this.title) {
        case 'Волонтерство':
          return VolunteeringIcon;
        case 'Здравоохранение и уход':
          return HealthcareIcon;
        case 'Миграция':
          return MigrationIcon;
        case 'Политика Международного Движения Красного Креста и Красного Полумесяца':
          return YouthPolicyIcon;
        case 'Чрезвычайные ситуации':
          return EmergencyIcon;
        default:
          return AdditionallyIcon;
      }
    },
  },
};
</script>

<style scoped>

</style>
