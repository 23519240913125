<template>
  <div class="list__item">
    <component
      :is="fileIcon"
      class="list__icon"
    />
    {{ material.name }}
    <component
      :is="statusIcon"
      class="list__status"
    />
  </div>
</template>

<script>
import ProgressDone from '@/components/icons/statuses/ProgressDone.vue';
import ProgressNotStarted from '@/components/icons/statuses/ProgressNotStarted.vue';
import ProgressDoing from '@/components/icons/statuses/ProgressDoing.vue';
import StatusFail from '@/components/icons/statuses/StatusFail.vue';
import PptxIcon from '@/components/icons/files/PptxIcon.vue';
import PdfIcon from '@/components/icons/files/PdfIcon.vue';
import DocxIcon from '@/components/icons/files/DocxIcon.vue';
import VideoIcon from '@/components/icons/files/VideoIcon.vue';
import ListIcon from '@/components/icons/types/List.vue';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'MaterialListItem',

  props: {
    material: {
      type: [Object, String],
      required: true,
    },
  },

  computed: {
    fileIcon() {
      if (this.material.meta?.fileExtension === 'pptx') return PptxIcon;
      if (this.material.meta?.fileExtension === 'pdf') return PdfIcon;
      if (this.material.meta?.fileExtension === 'docx') return DocxIcon;
      if (this.material.type === 'quiz') return ListIcon;
      return VideoIcon;
    },

    statusIcon() {
      const { status } = this.material.statistic;

      switch (status) {
        case ACTIVITY_STATUSES.done:
          return ProgressDone;
        case ACTIVITY_STATUSES.notStarted:
          return ProgressNotStarted;
        case ACTIVITY_STATUSES.rejected:
          return StatusFail;
        default:
          return ProgressDoing;
      }
    },
  },
};
</script>

<style scoped>

</style>
