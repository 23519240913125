<template>
  <div>
    <input
      :checked="isChecked"
      :disabled="disabled"
      :type="type"
      :class="answerClass + '__input'"
      @click="$emit('change')"
    >
    <div :class="answerClass + '__body'">
      <div :class="answerClass + '__title'">{{ answer.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Answer',

  props: {
    answer: {
      type: Object,
      required: true,
    },
    answerState: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    isChecked() {
      const checked = ['selected', 'invalid', 'valid'];
      return checked.includes(this.answerState);
    },

    answerClass() {
      if (this.type === 'radio') {
        return 'testing-radio';
      }
      if (this.type === 'checkbox') {
        return 'testing-checkbox';
      }
      return '';
    },
  },
};
</script>

<style scoped>

</style>
