<template>
  <section class="section section-get">
    <div class="content">
      <div class="get">
        <div class="get__content">
          <div class="section-header">
            <h2 class="section-title">Академия поможет</h2>
          </div>
          <div class="get__group">
            <div class="get__item">
              <div class="get__pic">
                <img src="@/assets/images/icons/icon__get-1.svg" alt="Навык" class="get__icon">
              </div>
              <h3 class="get__title">Освоить навык или профессию</h3>
              <div class="get__text">Готовим добровольцев и сотрудников в гуманитарной и
                медико-социальной сферах
              </div>
            </div>
            <div class="get__item">
              <div class="get__pic">
                <img src="@/assets/images/icons/icon__get-2.svg" alt="Карьера" class="get__icon">
              </div>
              <h3 class="get__title">Построить карьеру</h3>
              <div class="get__text">Помогаем создать траекторию карьерного роста и
                трудоустроиться в общественные и медицинские организации
              </div>
            </div>
            <div class="get__item">
              <div class="get__pic">
                <img
                  src="@/assets/images/icons/icon__get-3.svg"
                  alt="Исследование"
                  class="get__icon">
              </div>
              <h3 class="get__title">Провести исследования</h3>
              <div class="get__text">Исследуем работу общественных организаций, разрабатываем
                новые решения и практики для гуманитарной деятельности
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionGet',
};
</script>

<style scoped>

</style>
