<template>
  <v-footer
    class="footer py-11 py-lg-16 px-0"
    color="#f9f9f9"
  >
    <v-container>
      <v-row
        align="start"
        :justify="justify"
      >
        <v-col
          cols="12"
          lg="5"
        >
          <Partners class="footer-partners"/>
        </v-col>

        <v-col
          cols="12"
          lg="3"
        >
          <Menu/>
        </v-col>

        <v-col
          cols="12"
          lg="4"
        >
          <Address />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Menu from '@/components/footer/Menu.vue';
import Partners from '@/components/footer/Partners.vue';
import Address from '@/components/footer/Address.vue';

export default {
  name: 'Footer',

  components: {
    Partners,
    Menu,
    Address,
  },
  computed: {
    justify() {
      let result = 'space-between';
      if (this.$vuetify.breakpoint.mobile) {
        result = 'space-around';
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
