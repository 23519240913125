<template>
  <div class="testing__form">
    <label
      v-for="answer in question.meta.answers"
      :key="answer.id"
      :class="[
        labelClass,
        labelStatusClass(answer)
      ]"
    >
      <Answer
        :answer="answer"
        :disabled="isQuizCompleted"
        :answerState="answerState(answer)"
        :type="questionType"
        @change="$emit('change', answer.id)"
      />
    </label>
  </div>
</template>

<script>
import Answer from '@/components/viewer/quizViewer/Answer.vue';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'QuizCard',

  components: {
    Answer,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isQuizCompleted() {
      return this.question.statistic.status === ACTIVITY_STATUSES.done;
    },

    questionType() {
      if (!this.question.meta.is_multiple) {
        return 'radio';
      }
      if (this.question.meta.is_multiple) {
        return 'checkbox';
      }
      return '';
    },

    labelClass() {
      if (this.questionType === 'radio') {
        return 'testing-radio';
      }
      if (this.questionType === 'checkbox') {
        return 'testing-checkbox';
      }
      return '';
    },
  },

  created() {
  },

  methods: {
    answerState(answer) {
      if (!this.isQuizCompleted) {
        return answer.is_selected ? 'selected' : '';
      }

      const foundAnswer = this.question.statistic?.meta?.answers?.find((a) => a.id === answer.id);

      if (!foundAnswer) {
        return 'disabled';
      }

      if (!foundAnswer?.is_selected && foundAnswer?.is_correct) {
        return 'missed-correct';
      }

      if (foundAnswer?.is_correct) {
        return 'valid';
      }

      if (foundAnswer.is_correct !== undefined && !foundAnswer.is_correct) {
        return 'invalid';
      }

      return '';
    },

    labelStatusClass(answer) {
      if (!this.question.meta.is_multiple) {
        if (this.answerState(answer) === 'valid' || this.answerState(answer) === 'missed-correct') {
          return 'testing-radio--success';
        }
        if (this.answerState(answer) === 'invalid') {
          return 'testing-radio--error';
        }
      }

      if (this.question.meta.is_multiple) {
        if (this.answerState(answer) === 'valid' || this.answerState(answer) === 'missed-correct') {
          return 'testing-checkbox--success';
        }
        if (this.answerState(answer) === 'invalid') {
          return 'testing-checkbox--error';
        }
      }
      return '';
    },
  },
};
</script>

<style scoped>

</style>
