<template>
  <!--
    <v-scale-transition hide-on-leave>
  -->
  <v-skeleton-loader
    :key="isLoading ? 'd' : 'i'"
    :class="[ isLoading ? 'list__virtual--dummy' : 'list__virtual--item' ]"
    tile
    light
    :loading="false"
    aria-busy="false"
    :height="height"
    :width="widthComputed"
    :max-width="maxWidthComputed"
  >
    <div v-if="!isLoading" :key="isLoading ? 'd' : 'i'">
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          class="mx-auto"
          :width="widthComputed"
          :max-width="maxWidthComputed"
          :height="height"
          :to="to"
          :elevation="hover ? 12 : 3"
        >
          <v-img
            class="align-end mb-1"
            height="200px"
            max-height="200px"
            :src="imageSrcComputed"
            gradient="to bottom, rgba(255,255,255,0), rgba(255,255,255,1)"
          >
            <div class="px-8">
              <p class="mb-0 font-weight-medium">{{ imageLabel }}</p>
            </div>
          </v-img>

          <v-sheet
            height="70"
            width="100%"
            class="px-8"
          >
            <p class="text-h4 font-weight-bold rc-card-truncate__title">{{ titleComputed }}</p>
          </v-sheet>

          <v-sheet
            height="60"
            width="100%"
            class="px-8"
          >
            <div class="rc-card-truncate__description">
              {{ descriptionComputed }}
            </div>
          </v-sheet>

          <v-card-actions v-if="linkLabelComputed && !publicPage" class="px-7 pt-4 pb-10 mt-auto">
            <v-sheet height="54" color="transparent">
            <v-progress-circular
              v-if="showProgressComputed"
              size="54"
              width="1"
              color="green"
              rotate="-90"
              :value="progressComputed"
            >
              {{ progressComputed }}%
            </v-progress-circular>
            </v-sheet>
            <span
              class="text-body-1 text-sm-body-2 text-md-body-1 font-weight-medium"
              :class="showProgressComputed ? 'ml-4' : ''"
            >
              {{ linkLabelComputed }}
              <v-icon small>mdi-arrow-right-thin</v-icon>
            </span>
          </v-card-actions>
          <v-card-actions v-else-if="publicPage" class="px-7 pt-4 pb-10 mt-auto flex flex-column">
            <span
              class="text-body-1 text-sm-body-2 text-md-body-1 font-weight-medium"
            >
              <router-link
                :to="to"
                class="course-academy__link"
              >
        Подробнее
        <img
          src="@/assets/images/icons/icon__angle-right--red.svg"
          alt="Вперёд"
        >
      </router-link>
            </span>
          </v-card-actions>
        </v-card>
      </v-hover>
    </div>
  </v-skeleton-loader>
  <!--
    </v-scale-transition>
  -->
</template>

<script>
import progressMixin from '@/mixins/progress.mixin';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

const DEFAULT_IMG = require('@/assets/images/courses/course__card-2.jpg');

export default {
  name: 'RcItemCard',
  mixins: [
    progressMixin,
  ],
  props: {
    /*
    showProgress: {
      type: Boolean,
      default: true,
    },
    */
    progress: {
      type: [String, Number],
      default: undefined,
    },
    activity: {
      type: Object,
      default: undefined,
    },
    width: {
      type: [String, Number],
      default: undefined,
    },
    height: {
      type: [String, Number],
      default: 430,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    imageSrc: {
      type: [String, Object],
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: undefined,
    },
    linkLabel: {
      type: String,
      default: undefined,
    },
    imageLabel: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    publicPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkLabelComputed() {
      if (this.linkLabel) {
        return this.linkLabel;
      }

      const status = this.activity?.statistic?.status;
      switch (status) {
        // case 1:
        case ACTIVITY_STATUSES.doing:
          return 'Продолжить обучение';
        case ACTIVITY_STATUSES.rejected:
        case ACTIVITY_STATUSES.done:
          return 'Пройти повторно';
        default:
          return 'Пройти курс';
      }
    },
    maxWidthComputed() {
      let result;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          result = '80vw';
          break;

        case 'sm':
          result = 300 - 12;
          break;

        case 'md':
        case 'lg':
        case 'xl':
          result = 350;
          break;

        // no default
      }
      return result;
    },
    widthComputed() {
      if (this.width) {
        return this.width;
      }

      let result;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          // result = '100%';
          break;

        case 'sm':
          result = 300 - 12;
          break;

        case 'md':
        case 'lg':
        case 'xl':
          result = 350;
          break;

        // no default
      }
      return result;
    },
    isLoading() {
      return this.loading ?? this.activity?.name === 'dummy';
    },
    imageUrl() {
      if (!this.activity?.meta?.imageLowres) {
        return;
      }

      const imgs = this.activity.meta;

      let result = imgs.imageLowres;
      if (this.$vuetify.breakpoint.lgAndUp) {
        result = imgs.imageHighres;
      }
      if (this.$vuetify.breakpoint.md) {
        result = imgs.imageMidres;
      }

      // eslint-disable-next-line
      return `https://${result}`;
    },

    imageSrcComputed() {
      return this.imageSrc || this.imageUrl || DEFAULT_IMG;
    },
    titleComputed() {
      return this.title || this.activity?.name || '';
    },
    descriptionComputed() {
      return this.description || this.activity.description || '';
    },

    showProgressComputed() {
      /*
      if (this.showProgress) {
        return this.showProgress;
      }

      return !!this.activity;
      */
      return this.progressComputed > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
%rc-truncate {
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.rc-card-truncate {
  &__description {
    @extend %rc-truncate;
    -webkit-line-clamp: 3;
  }

  &__title {
    @extend %rc-truncate;
    -webkit-line-clamp: 2;
  }
}
</style>
