<template>
<div v-if="this.items.length > 0">
<v-container v-if="title">
  <span class="text-h3 text-lg-h2 mr-2">{{ title }}</span>
  <span class="red--text text-h3 text-lg-h2">{{ items.length }}</span>
</v-container>
<v-container class="pa-0">
  <v-sheet
    color="transparent"
    max-width="100vw"
  >
    <v-row justify="start" no-gutters>
  <v-slide-group
    center-active
    mobile-breakpoint="700"
  >
    <template #default>
      <v-slide-item
        v-for="(item, index) in items"
        :key="index"
        v-slot="{ active, toggle }"
        v-intersect="{
          handler: onIntersect.bind(this, item, index),
              options: {
                threshold: 0.8,
              }
            }"
        :class="itemClass"
      >
        <slot
          :item="item"
          :active="active"
          :toggle="toggle"
        />
      </v-slide-item>
    </template>
<!--
    <template #next>
      <v-sheet
        v-if="showNextComputed"
        color="transparent"
        height="100%"
        width="45"
        class="pb-12 pt-4"
      >
        <v-card
          height="100%"
          color="white"
          class="d-flex align-center rounded-lg rounded-l-0"
        >
          <v-icon x-large color="red">
            {{ iconNext }}
          </v-icon>
        </v-card>
      </v-sheet>
    </template>

    <template #prev>
      <v-sheet
        v-if="showPrevComputed"
        color="transparent"
        height="100%"
        width="45"
        class="pb-12 pt-4"
      >
        <v-card
          height="100%"
          color="white"
          class="d-flex align-center rounded-lg rounded-r-0"
        >
          <v-icon x-large color="red" class="my-auto">
            {{ iconPrev }}
          </v-icon>
        </v-card>
      </v-sheet>
    </template>
-->
  </v-slide-group>
    </v-row>
  </v-sheet>
</v-container>
</div>
</template>

<script>
export default {
  name: 'RcGroupSlider',

  props: {
    items: {
      type: Array,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    itemClass: {
      type: String,
      default: 'px-4 pb-12 pt-6',
    },
  },

  data: () => ({
    showPrev: true,
    showNext: true,
  }),

  computed: {
    showPrevComputed() {
      return this.showPrev;
    },
    showNextComputed() {
      return this.showNext;
    },
    iconPrev() {
      return '$prev';
    },
    iconNext() {
      return '$next';
    },
  },
  methods: {
    onIntersect(item, index, entries, observer, isIntersecting) {
      // const isItLast = index + 1 === this.items.lenght;
      // const isItFirst = index === 0;
      switch (index) {
        case 0:
          this.showPrev = !isIntersecting;
          break;
        case this.items.length - 1:
          this.showNext = !isIntersecting;
          break;

        // no default
      }

      /*
      if (isItLast) {
        this.showNext = isIntersecting;
      }
      if (isItFirst) {
        this.showPrev = isIntersecting;
      }
      */
    },
  },
};
</script>

<style lang="scss" scoped>
.v-slide-group {
  ::v-deep &__prev {
    z-index: 2;
    margin-right: -4px;
    & .v-card {
      opacity: 0.8;
    }
  }
  ::v-deep &__next {
    z-index: 2;
    margin-left: -4px;
    & .v-card {
      opacity: 0.8;
    }
  }
}
</style>
