<!-- eslint-disable max-len -->
<template>
  <section class="section section--white section-academy">
    <div ref="content" class="content content--mw-initial">
      <div class="academy">
        <div class="section-header">
          <h2 class="section-title">Академия Красного Креста</h2>
        </div>

        <v-container class="tags">
          <h3 class="mb-4">Посмотрите курсы по темам</h3>
          <TagFilter
            v-if="tagList.length"
            :tags="tagList"
            :selectedTagsId="selectedTagsId"
            class="academy__tags-guest"
            @clickedBadge="toggleTag"
          />
        </v-container>

        <RcAnimate>
          <RcItemsList
            :items="filteredCoursesByTags"
            :item-height="$vuetify.breakpoint.mdAndDown ? 450 : 470"
            :height="`calc(100vh - ${$vuetify.application.bar + $vuetify.application.top}px)`"
            :full-height="true"
            @pageChange="scrollTo"
          >
            <template v-slot:default="{ item }">
              <RcItemCard
                :key="item._id"
                :activity="item"
                :to="`/public-course/${item._id}`"
                class="pb-4"
                image-label="Курс"
                public-page
              />
            </template>
          </RcItemsList>
        </RcAnimate>
        <div class="buttons">
          <v-btn to="/auth" color="red" dark large :block="$vuetify.breakpoint.mobile">
            Войти на платформу
          </v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import coursesExamples from '@/components/mainGuest/courseExamples.json';
import RcItemsList from '@/components/rc/RcItemsList.vue';
import RcItemCard from '@/components/rc/RcItemCard.vue';
import RcAnimate from '@/components/rc/RcAnimate.vue';
import TagFilter from '@/components/Tag/TagFilter.vue';
import tagsMixin from '@/mixins/tags.mixin';

export default {
  name: 'SectionAcademy',

  components: {
    TagFilter,
    RcAnimate,
    RcItemCard,
    RcItemsList,
  },

  mixins: [tagsMixin],

  data() {
    return {
      coursesExamples,
      selectedTagsId: [],
    };
  },

  computed: {
    coursesList() {
      return this.$store.state.courses.publicCoursesList;
    },
    filteredCoursesByTags() {
      if (!this.selectedTagsId.length) {
        return this.coursesList;
      }
      return this.coursesList
        .filter((course) => this.selectedTagsId.some((tagId) => course.tag_ids.includes(tagId)));
    },
  },

  async created() {
    if (!this.coursesList.length) {
      await this.$store.dispatch('courses/fetchPublicCoursesList');
    }
  },

  methods: {
    scrollTo() {
      this.$refs.content.scrollIntoView({
        behavior: 'auto',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__content {
  .course-wapper > div {
    height: 100%;
  }
  .course-wapper:not(:first-child, :last-child) {
    margin: 0 15px 30px;
  }
  .course-wapper:first-child {
    margin: 0 0 30px 5px;
  }
  .course-wapper:last-child {
    margin: 0 5px 30px 0;
  }
}

::v-deep .v-slide-group {
  &__next,
  &__prev {
    i {
      font-size: 32px;
    }
  }
}

::v-deep .v-btn__content > i {
  font-size: 105px;
}
::v-deep .v-carousel__controls {
  bottom: -50px;
}
::v-deep .v-carousel__controls__item {
  &.theme--dark.v-btn.v-btn--icon {
    color: #86868633;
  }

  &:before {
    display: none;
  }
  &.v-btn--active {
    i {
      opacity: 1;
      color: #db1c1c;
    }
  }
  i {
    font-size: 62px !important;
    height: 3px !important;
    overflow: hidden;
    width: auto !important;
  }

  .v-ripple__container {
    display: none;
  }
}
@media (max-width: 767px) {
  ::v-deep .v-sheet.theme--light.transparent {
    max-width: calc(100vw - 30px) !important;
  }
}

.tags {
  max-width: 630px;
  text-align: start;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  ::v-deep .tag:not(.selected) {
    background-color: transparent;
    &:hover:not(.more-button) {
      background-color: rgba(219, 28, 28, 0.08);
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}
</style>
