<template>
  <div class="testing__material">
    <PdfPlayer
        v-if="activity.type === 'material'"
        :key="key"
        :activity="activity"
    />
    <VideoPlayer
        v-else-if="activity.type === 'video'"
        :activity="activity"
    />
    <TaskViewerPosted
        v-else-if="activity.type === 'task' && isTaskPosted"
        :activity="activity"
    />
    <TaskViewer
        v-else-if="activity.type === 'task'"
        :activity="activity"
    />
    <QuizViewer
        v-else-if="activity.type === 'quiz'"
        :activity="activity"
    />
  </div>
</template>

<script>
import PdfPlayer from '@/components/viewer/pdfPlayer/PdfPlayer.vue';
import VideoPlayer from '@/components/viewer/videoPlayer/VideoPlayer.vue';
import TaskViewer from '@/components/viewer/taskViewer/TaskViewer.vue';
import TaskViewerPosted from '@/components/viewer/taskViewer/TaskViewerPosted.vue';
import QuizViewer from '@/components/viewer/quizViewer/QuizViewer.vue';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'ViewerPlayer',

  components: {
    PdfPlayer,
    VideoPlayer,
    TaskViewer,
    TaskViewerPosted,
    QuizViewer,
  },

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      key: '', // will force component remount on activity update
    };
  },

  computed: {
    isTaskPosted() {
      const { status } = this.activity.statistic;

      return status !== ACTIVITY_STATUSES.notStarted;
    },
  },

  watch: {
    activity: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.key = newValue?.meta?.url ? `file-${newValue.meta.url}` : 'empty';
      },
    },
  },
};
</script>

<style scoped>
</style>
