<template>
  <div class="address text-center text-lg-left">
    <p><a href="tel:+7(499)126-75-71">+7 (499) 126-75-71</a></p>
    <div class="emails">
      <p>Подача заявок на обучение: <a href="mailto:academy@redcross.ru">academy@redcross.ru</a></p>
      <p>Техподдержка: <a href="support.academy@redcross.ru">support.academy@redcross.ru</a></p>
      <p>
        По общим вопросам: <a href="mailto:info.academy@redcross.ru">info.academy@redcross.ru</a>
      </p>
    </div>
    <v-row
      no-gutters
    >
      <SocialLinks
        :justify="socJustify"
      />
    </v-row>
  </div>
</template>

<script>
import SocialLinks from '@/components/footer/SocialLinks.vue';

export default {
  name: 'Address',
  components: {
    SocialLinks,
  },
  computed: {
    socJustify() {
      let result = 'start';
      if (this.$vuetify.breakpoint.mobile) {
        result = 'center';
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.emails {
  p {
    line-height: 1rem;
    margin-bottom: .5rem;
  }
  padding-bottom: 1rem;
}
</style>
