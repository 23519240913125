<template>
  <div class="page">
    <Header/>

    <v-main>
      <router-view/>
    </v-main>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
  name: 'DefaultLayout',

  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>

</style>
