<template>
  <div v-if="task" class="task">
    <div class="task__header">
      <div class="block-subtitle">
        <span v-if="status.status === 'rejected'">Задание отклонено</span>
        <span v-else-if="status.status === 'done'">Задание принято</span>
        <span v-else>Задание отправлено</span>
      </div>
      <div class="status" :class="statusClassName">
        <component :is="taskStatus" class="status__icon"></component>
        {{ status.label }}
      </div>
    </div>
    <div v-if="activity.meta.url" class="buttons">
      <LinkToSample :url="activity.meta.url"/>
    </div>
    <div class="task__body">
      <div class="chat">
        <div
            v-for="(message, index) in messages"
            :key="index"
        >
          <div v-if="message.author_id === user.id" class="chat__my">
            <div class="chat__header">
              <div class="chat__name">Вы</div>
              <div class="chat__date">
                {{ getMessageSentTime(message.sent_at) }}
              </div>
            </div>
            <div class="chat__text">
              <p>
                {{ message.text }}
              </p>
            </div>
            <LinkToFile
                v-if="message.attachment_url"
                :fileName="message.attachment_name"
                :url="message.attachment_url"
            />
          </div>
          <div v-else class="chat__admin">
            <div class="chat__header">
              <div class="chat__name">Менеджер</div>
              <div class="chat__date">
                {{ getMessageSentTime(message.sent_at) }}
              </div>
            </div>
            <div class="chat__text">
              <p>{{ message.text }}</p>
            </div>
            <LinkToFile
                v-if="message.attachment_url"
                :fileName="message.attachment_name"
                :url="message.attachment_url"
            />
            <div
                v-if="getMessageVerdict(message) === 'reject'"
                class="status-label status-label--fail"
            >
              Задание не принято
            </div>
            <div
                v-if="getMessageVerdict(message) === 'accept'"
                class="status-label status-label--success"
            >
              Задание принято
            </div>
          </div>
        </div>
      </div>
      <div v-if="status.status !== 'done'" class="reply">
        <label class="field">
          <input
              v-model="answer"
              type="text"
              class="field__value"
              placeholder="Введите сообщение"
          >
          <span class="field__label">Введите сообщение</span>
        </label>
        <div class="reply__attach">
          <div
              v-if="file"
              class="uploaded"
          >
            <div class="uploaded__text">{{ file.name }}</div>
            <div class="uploaded__remove" @click="removeFile"></div>
          </div>
          <label v-show="!file" class="button button--md button--hollow">
            Прикрепить файл
            <img
                src="@/assets/images/icons/icon__attachment--red.svg"
                width="15"
                height="15"
                alt="Вложение"
                class="button__icon"
            >
            <input
                id="file-input"
                ref="fileInput"
                type="file"
                accept=".pdf, .docx, .pptx, .zip"
                class="button__file"
                @change="uploadFile"
            >
          </label>
        </div>
        <button
            class="button reply__button"
            :class="{'button--disabled': !answer || loading}"
            @click="sendAnswer"
        >
          Отправить
          <img src="@/assets/images/icons/icon__send--white.svg" alt="" class="button__icon">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressDone from '@/components/icons/statuses/ProgressDone.vue';
import ProgressNotStarted from '@/components/icons/statuses/ProgressNotStarted.vue';
import StatusFail from '@/components/icons/statuses/StatusFail.vue';
import LinkToSample from '@/components/viewer/taskViewer/LinkToSample.vue';
import Program from '@/api/program';
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import LinkToFile from '@/components/viewer/taskViewer/LinkToFile.vue';
import toastMixin from '@/mixins/toast.mixin';
import { checkFile } from '@/core/helpers/checkFile';

export default {
  name: 'TaskViewerPosted',

  components: {
    LinkToSample,
    LinkToFile,
  },

  mixins: [toastMixin],

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      task: null,
      answer: '',
      file: null,
      loading: false,
      messages: null,
    };
  },

  computed: {
    ...mapState('user', {
      user: 'user',
    }),

    status() {
      const statusId = this.activity.statistic.status;

      if (statusId === 3) {
        return {
          status: 'rejected',
          label: 'Отклонено',
        };
      }

      if (statusId === 4) {
        return {
          status: 'done',
          label: 'Принято',
        };
      }

      return {
        status: 'doing',
        label: 'На проверке',
      };
    },

    taskStatus() {
      if (this.status.status === 'done') {
        return ProgressDone;
      }
      if (this.status.status === 'rejected') {
        return StatusFail;
      }
      return ProgressNotStarted;
    },

    statusClassName() {
      if (this.status.status === 'done') {
        return 'status--success';
      }
      if (this.status.status === 'rejected') {
        return 'status--fail';
      }
      return '';
    },
  },

  async created() {
    await this.getTask();
  },

  methods: {
    async getTask() {
      const taskId = this.activity._id;
      const {
        programId,
        courseId,
      } = this.$route.params;
      const activityContext = [programId, courseId];
      const response = await Program.getTask(taskId, activityContext);
      this.messages = response.data.Chat.messages;
      this.task = response.data;
    },

    async sendAnswer() {
      this.loading = true;

      const taskId = this.activity._id;

      const {
        programId,
        courseId,
      } = this.$route.params;
      const activityContext = [programId, courseId];

      const data = {
        activity_context: activityContext,
        text: this.answer,
        message_type: 1,
      };

      if (this.file) {
        data.attachment_url = await this.sendMaterial();
        data.attachment_name = this.file.name;
      }

      await this.sendMessage(taskId, data);

      const message = {
        sent_at: Date.now(),
        text: this.answer,
        author_id: this.user.id,
        attachment_url: data.attachment_url,
        attachment_name: this.file?.name,
        type: 1,
      };

      this.messages.push(message);

      this.answer = '';
      this.removeFile();

      this.loading = false;
    },

    async sendMaterial() {
      const response = await Program.sendFile(this.file);
      return response.data.url;
    },

    async sendMessage(taskId, data) {
      await Program.sendMessage(taskId, data);
    },

    getMessageSentTime(date) {
      return format(new Date(date), 'dd MMMM, yyyy, HH:mm', { locale: ru });
    },

    getMessageVerdict(message) {
      if (message.extension) {
        if (message.extension[0].Value === 1) {
          return 'accept';
        }
        if (message.extension[0].Value === 2) {
          return 'reject';
        }
      }
      return '';
    },

    uploadFile(event) {
      const [file] = event.target.files;
      if (!file) return;

      const error = checkFile(file);
      if (error) {
        this.showError(error);
      } else {
        this.file = file;
        this.$refs.fileInput.value = '';
      }
    },

    removeFile() {
      this.file = null;
    },
  },
};
</script>

<style scoped>

</style>
