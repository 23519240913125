import User from '@/api/user';
import authorization from '@/api/authorization';

export default {
  namespaced: true,

  state: {
    user: null,
    signInErrorMsg: null,
    signInPending: false,
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    email(state) {
      return state.user?.email || '';
    },
    firstName(state) {
      return state.user?.first_name || '';
    },
    lastName(state) {
      return state.user?.last_name || '';
    },
    fullName(state, getters) {
      if (!state.user) {
        return '';
      }
      const result = [];
      if (getters.firstName) {
        result.push(getters.firstName);
      }
      if (getters.lastName) {
        result.push(getters.lastName);
      }
      return result.join(' ').trim();
    },
  },

  mutations: {
    setUserInfo(state, data) {
      state.user = data;
    },
    setSignInPending(state, value) {
      state.signInPending = !!value;
    },
    setSignInErrorMessage(state, message) {
      state.signInErrorMessage = message;
    },
  },

  actions: {
    /**
     * Login in lms
     * @return boolean: true on success login, false if something go wrong
     */
    async signIn({ dispatch, commit }, { username, password }) {
      commit('setSignInPending', true);
      let message = null;
      let result = false;

      try {
        await authorization.signIn({
          username,
          password,
        });

        await dispatch('appStart', null, { root: true });

        result = true;
      } catch (error) {
        message = 'Что-то пошло не так...';
        const resStatus = error.response?.status;
        switch (resStatus) {
          case 400:
            message = 'Мы не нашли такую связку почты и пароля';
            break;
          case 404:
            message = 'Пользователь не найден';
            break;

          // no default
        }
      }
      commit('setSignInErrorMessage', message);
      commit('setSignInPending', false);
      return result;
    },
    async onPasswordChanged() {
      await User.onPasswordChanged();
    },
    async onRegistrationSuccess() {
      await User.onRegistrationSuccess();
    },
    async saveUserInfo({ dispatch }, { firstName, lastName, email }) {
      try {
        await User.saveUserInfo({ firstName, lastName, email });
        const response = await dispatch('getUserInfo');
        return response.data;
      } catch (e) {
        return e.response;
      }
    },
    async getUserInfo({ commit }) {
      try {
        const response = await User.getUserInfo();
        commit('setUserInfo', response.data);
        return response.data;
      } catch (e) {
        return e.response;
      }
    },

    async signOut({ commit, dispatch }) {
      try {
        const response = await authorization.signOut();
        commit('setUserInfo', null);
        dispatch('courses/cleanProgramsList', null, { root: true });
        return response.data;
      } catch (e) {
        return e.response;
      }
    },
  },
};
