const HESK_CATEGORIES = [
  {
    name: 'Сообщить о проблеме',
    description:
      'Опишите подробнее вашу проблему: что именно не работает? Какие действия вы выполняли, когда возникла проблема? С какого браузера вы посещали платформу и какая у вас операционная система?',
  },
  {
    name: 'Подать заявку на курс',
    description: 'Не забудьте написать название курса',
  },
  {
    name: 'Задать вопрос про работу портала',
    description: 'Задайте свой вопрос и мы вернёмся к вам с ответом',
  },
  {
    name: 'Оставить отзыв',
    description: 'Поделитесь обратной связью. Это поможет нам стать лучше',
  },
  { name: 'Другое', description: 'Не нашли подходящей темы? Напишите, что искали' },
];

export default HESK_CATEGORIES;
