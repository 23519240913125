<template>
  <section id="section-whom" class="section section-whom">
    <div class="content">
      <div class="whom">
        <div class="section-header">
          <h2 class="section-title">Кому будет полезна Академия</h2>
        </div>
        <div class="whom__group">
          <div class="whom__item">
            <div class="whom__pic">
              <img
                src="@/assets/images/icons/icon__whom-1.svg"
                alt="Сотрудникам"
                class="whom__icon">
            </div>
            <h3 class="whom__title">Сотрудникам и членам Российского Красного Креста</h3>
            <div class="whom__text">Чтобы изучить управление и организацию деятельности НКО</div>
          </div>
          <div class="whom__item">
            <div class="whom__pic">
              <img
                src="@/assets/images/icons/icon__whom-2.svg"
                alt="Специалистам"
                class="whom__icon">
            </div>
            <h3 class="whom__title">Профильным специалистам</h3>
            <div class="whom__text">Чтобы пройти подготовку и переподготовку в сферах оказания
              гуманитарной помощи
            </div>
          </div>
          <div class="whom__item">
            <div class="whom__pic">
              <img
                src="@/assets/images/icons/icon__whom-3.svg"
                alt="Волонтёрам"
                class="whom__icon"
              >
            </div>
            <h3 class="whom__title">Волонтёрам и добровольцам</h3>
            <div class="whom__text">Чтобы получить навыки для работы в общественных организациях
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionWhom',
};
</script>

<style scoped>

</style>
