<template>
  <img
    src="@/assets/images/icons/icon__theme-document.svg"
    alt="Файл"
  >
</template>

<script>
export default {
  name: 'File',
};
</script>

<style scoped>

</style>
