import Program from '@/api/program';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  namespaced: true,

  state: {
    coursesList: [],
    coursesFetching: false,

    publicCoursesList: [],

    coursesPage: 0,
    coursesPerPage: 20,
  },

  getters: {
    coursesList(state) {
      return state.coursesList || [];
    },
    publicCoursesList(state) {
      return state.publicCoursesList || [];
    },

    /**
     * courses for wheach we can show progress
     */
    coursesInProgressList(state) {
      const result = state.coursesList || [];
      return result.filter((course) => {
        // const isItHasMaxScore = course?.max_score > 0;
        // const isItScored = course?.statistic?.score > 0;

        const isStatusInWork = [
          ACTIVITY_STATUSES.doing,
        ].includes(course.statistic.status);
        return isStatusInWork;

        // return isItHasMaxScore && isItScored && isStatusInWork;
      });
    },
  },

  mutations: {
    setCoursesList(state, coursesList) {
      state.coursesList = coursesList;
    },

    setPublicCoursesList(state, coursesList) {
      state.publicCoursesList = coursesList;
    },

    setCoursesPage(state, pageNumber) {
      state.coursesPage = pageNumber;
    },
  },

  actions: {
    /**
     * Initiate courses list by fetching publicCourses and courses both
     */
    async initCourses({ getters, dispatch, rootGetters }) {
      if (!rootGetters['user/isAuthenticated']) {
        return;
      }

      if (getters.coursesList.length === 0) {
        await dispatch('fetchCoursesList');
      }
    },

    /**
     * Drop programs and courses lists to empty value and reset to 0 pagination
     */
    async cleanProgramsList({ commit }) {
      commit('setCoursesList', []);
      commit('setCoursesPage', 0);
    },

    async fetchCoursesList({ commit }) {
      try {
        const response = await Program.getCourses();
        const coursesList = response.data || [];
        commit('setCoursesList', coursesList);
      } catch (e) {
        //
      }
    },

    async fetchPublicCoursesList({ commit }) {
      const publicCourses = await Program.getPublicCourses();
      const courses = publicCourses.data.reduce((acc, curr) => {
        acc.push(curr);
        return acc;
      }, []);

      commit('setPublicCoursesList', courses);
      return courses;
    },
  },
};
