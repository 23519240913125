<template>
  <img
    src="@/assets/images/icons/icon__status--empty.svg"
    alt="Не просмотрен"
  >
</template>

<script>
export default {
  name: 'ProgressNotStarted',
};
</script>

<style scoped>

</style>
