<template>
  <form class="login__form" @submit.prevent="submit">
    <label class="field">
      <input
        v-model="form.firstName"
        type="text"
        class="field__value"
        placeholder="Имя"
        autofocus
      >
      <span class="field__label">Имя</span>
      <span
        v-if="$v.form.firstName.$dirty && !$v.form.firstName.required"
        class="field__error"
      >
                Заполните поле
              </span>
    </label>
    <label class="field">
      <input
        v-model="form.lastName"
        type="text"
        class="field__value"
        placeholder="Фамилия"
      >
      <span class="field__label">Фамилия</span>
      <span
        v-if="$v.form.lastName.$dirty && !$v.form.lastName.required"
        class="field__error"
      >
                Заполните поле
              </span>
    </label>
    <label class="field">
      <input
        v-model="form.middleName"
        type="text"
        class="field__value"
        placeholder="Отчество"
      >
      <span class="field__label">Отчество</span>
      <span
        v-if="$v.form.middleName.$dirty && !$v.form.middleName.required"
        class="field__error"
      >
                Заполните поле
              </span>
    </label>
    <label class="field">
      <input
        v-model="form.username"
        type="email"
        class="field__value"
        placeholder="Электронная почта"
      >
      <span class="field__label">Электронная почта</span>
      <span
        v-if="$v.form.username.$dirty && !$v.form.username.required"
        class="field__error"
      >
                Заполните поле
              </span>
      <span
        v-else-if="$v.form.username.$dirty && !$v.form.username.email"
        class="field__error"
      >
                Некорректный E-mail
              </span>
      <span
        v-else-if="error"
        class="field__error"
      >
                {{ error }}
              </span>
    </label>
    <label class="field">
      <lera-password-input
        v-model="form.password"
        :is-password-correct.sync="isPasswordCorrect"
        type="password"
        autocomplete="new-password"
        class="field__value"
        placeholder="Придумайте пароль"
      />
      <span class="field__label">Пароль</span>
      <span
        v-if="$v.form.password.$dirty && !$v.form.password.required"
        class="field__error"
      >
                Заполните поле
              </span>
      <span
        v-else-if="$v.form.password.$dirty &&
         (!$v.form.password.passwordMatch || !isPasswordCorrect)"
        class="field__error"
      >
               Пароль должен содержать не менее 8-ми символов,
                минимум 1 заглавную, строчную букву, цифру, символ
              </span>
    </label>
    <label class="field">
      <input
        v-model="form.passwordRepeat"
        type="password"
        autocomplete="new-password"
        class="field__value"
        placeholder="Повторите пароль"
      >
      <span class="field__label">Подтверждение пароля</span>
      <span
        v-if="$v.form.passwordRepeat.$dirty && !$v.form.passwordRepeat.required"
        class="field__error"
      >
                Заполните поле
              </span>
      <span
        v-else-if="$v.form.passwordRepeat.$dirty
                 && !$v.form.passwordRepeat.sameAsPassword"
        class="field__error"
      >
                Пароли не совпадают
              </span>
    </label>
    <div class="field">
      <label class="checkbox">
        <input v-model="form.checked" type="checkbox">
        <span class="checkbox__text">
          Я согласен с условиями обработки
          <a
            href="Положение_об_обработке_ПД_обучающихся_АКК.docx"
            target="_blank"
          >персональных данных</a>
         </span>
      </label>
    </div>
    <v-btn
      v-if="!loading"
      :disabled="!isSecondStepAllowed"
      color="red"
      :dark="isSecondStepAllowed"
      large
      block
      type="submit"
    >
      К следующему шагу
    </v-btn>
    <button v-else class="button button--full button--disabled button--preloader"></button>
  </form>
</template>

<script>
import {
  email, helpers, required, sameAs,
} from 'vuelidate/lib/validators';
import { passwordRegexp } from '@/core/constants/regexp';

const passwordMatch = helpers.regex('passwordMatch', passwordRegexp);

export default {
  name: 'FirstStepForm',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        middleName: '',
        username: '',
        password: '',
        passwordRepeat: '',
        checked: true,
      },
      isPasswordCorrect: true,
    };
  },

  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      middleName: {
        required,
      },
      username: {
        required,
        email,
      },
      password: {
        required,
        passwordMatch,
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('password'),
      },
      checked: {
        sameAs: sameAs(() => true),
      },
    },
  },

  computed: {
    isSecondStepAllowed() {
      return !!(this.form.firstName
        && this.form.username
        && this.form.lastName
        && this.form.middleName
        && this.form.password
        && this.form.passwordRepeat
        && this.form.checked);
    },
    formValidComputed() {
      return !this.$v.form.$invalid && this.isPasswordCorrect;
    },
  },

  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.formValidComputed) {
        const data = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          middleName: this.form.middleName,
          username: this.form.username,
          password: this.form.password,
        };
        this.$emit('submit', data);
      }
    },
  },
};
</script>

<style scoped>

</style>
