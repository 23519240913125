<template>
  <v-row
    no-gutters
    justify="center"
    align="center"
  >
    <v-card
      v-for="partner in partnersList"
      :key="partner.name"
      :href="partner.href"
      flat
      color="transparent"
      :ripple="false"
      :height="partner.height"
      class="mx-1"
    >
      <v-img
        target="_blank"
        :src="partner.src"
        :alt="partner.name"
        max-width="100"
        :height="partner.height"
        contain
      />
    </v-card>
  </v-row>
</template>

<script>
import constantsMixin from '@/mixins/constantsLinks.mixin';

export default {
  name: 'Partners',

  mixins: [constantsMixin],

  computed: {
    partnersList() {
      const result = [
        {
          href: this.links.partner1,
          src: require('@/assets/images/partners/partner-1--x2.png'),
          name: 'РНИМУ им. Н.И. Пирогова',
          width: 97,
          height: 59,
        },
        {
          href: this.links.partner2,
          src: require('@/assets/images/logo.svg'),
          name: 'Российский красный крест',
          width: 50,
          height: 50,
        },
        {
          href: this.links.partner3,
          src: require('@/assets/images/partners/partner-3--x2.png'),
          name: 'Волонтёры медики',
          width: 112,
          height: 45,
        },
      ];
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
