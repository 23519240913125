<template>
  <div class="testing__panel">
    <div class="testing-result">
      <h3 class="block-pretitle">В этом тесте нет вопросов</h3>
      <div class="testing-result__empty"></div>
      <h3 class="block-subtitle">
        Подождите пока <br>
        администратор добавит <br>
        вопросы
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizEmpty',
};
</script>

<style scoped>

</style>
