<template>
  <div>
    <v-btn
      v-for="link in linksList"
      :key="link.to"
      :to="link.to"
      text
      plain
      :ripple="false"
      class="text-uppercase text-caption font-weight-bold"
    >
      {{ link.label }}
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'Menu',

  computed: {
    linksList() {
      return [
        {
          to: '/',
          label: 'Курсы',
        },
        {
          to: '/about',
          label: 'Об академии',
        },
        {
          to: '/docs',
          label: 'Документы',
        },
      ];
    },
  },
};
</script>
