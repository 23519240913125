<template>
  <div class="course__themes">
    <div class="section-pretitle">
      {{ group.activity.description }}
    </div>
    <GroupSlide
        v-for="(group, index) in groups"
        :key="index"
        :group="group"
    />
  </div>
</template>

<script>
import GroupSlide from '@/components/course/group/GroupSlide.vue';

export default {
  name: 'Group',

  components: {
    GroupSlide,
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  computed: {
    groups() {
      return this.group.children?.filter((activity) => activity.activity.type === 'group');
    },
  },
};
</script>

<style scoped>

</style>
