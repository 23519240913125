<template>
  <main class="main main-documents">

    <section
        v-scrollanimation="{className: 'section--animated'}"
        class="section section-documents"
    >
      <div class="content">
        <div class="section-header">
          <h2 class="section-title">Документы</h2>
        </div>
        <div class="documents">
          <DocumentCard
              v-for="(document, index) in documents"
              :key="index"
              :title="document.title"
              :files="document.files"
          />
        </div>
      </div>
    </section>

  </main>
</template>

<script>
import DocumentCard from '@/components/documents/DocumentCard.vue';
import documents from '@/core/constants/documents';

export default {
  name: 'Documents',

  components: {
    DocumentCard,
  },

  computed: {
    documents() {
      return documents;
    },
  },
};
</script>

<style lang="less">
@import '~@/assets/styles/pages/documents.less';
</style>
