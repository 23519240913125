<template>
  <div class="video-player">
    <div class="plyr plyr__video-embed">
      <iframe
        ref="player"
        :src="linkSrc"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { Mutex } from 'async-mutex';
import urlParser from 'js-video-url-parser';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import YouTubePlayer from 'youtube-player';
import VimeoPlayer from '@vimeo/player';
import { mapActions } from 'vuex';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

const controls = [
  // 'play-large', // The large play button in the center
  // 'restart', // Restart playback
  // 'rewind', // Rewind by the seek time (default 10 seconds)
  'play', // Play/pause playback
  // 'fast-forward', // Fast forward by the seek time (default 10 seconds)
  'progress', // The progress bar and scrubber for playback and buffering
  'current-time', // The current time of playback
  'duration', // The full duration of the media
  'mute', // Toggle mute
  'volume', // Volume control
  // 'captions', // Toggle captions
  'settings', // Settings menu
  'pip', // Picture-in-picture (currently Safari only)
  'airplay', // Airplay (currently Safari only)
  'fullscreen', // Toggle fullscreen
];

const mutex = new Mutex();

export default {
  name: 'VideoPlayer',

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      player: null,
      completeThreshold: 0.95,
    };
  },

  computed: {
    parsedLink() {
      if (this.isRutubeLink) {
        const link = this.activity.meta.url;
        const url = new URL(link);
        if (url.hostname === 'rutube.ru') {
          const params = url.pathname.split('/')
            .filter((v) => v.length);
          return {
            id: params[params.length - 1],
            mediaType: 'video',
            provider: 'rutube',
          };
        }
      }
      return urlParser.parse(this.activity.meta.url);
    },

    isYoutubeLink() {
      return this.parsedLink.provider === 'youtube';
    },

    isRutubeLink() {
      return this.activity.meta.url.includes('rutube');
    },

    isVimeoLink() {
      return this.parsedLink.provider === 'vimeo';
    },

    linkSrc() {
      if (this.isYoutubeLink) {
        return `https://www.youtube.com/embed/${this.parsedLink.id}?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;
      }
      if (this.isRutubeLink) {
        return `https://rutube.ru/play/embed/${this.parsedLink.id}`;
      }
      return `https://player.vimeo.com/video/${this.parsedLink.id}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
    },
  },

  mounted() {
    this.createPlayer();
  },

  beforeDestroy() {
    if (this.player.removeEventListener) {
      this.player.removeEventListener('load', this.handleRutubeEvents);
    }
    window.removeEventListener('message', this._handleRutubeEvents);

    clearInterval(this.player.checkProgress);
  },

  methods: {
    ...mapActions('viewer', {
      setActivityAsStarted: 'setActivityAsStarted',
      setActivityAsCompleted: 'setActivityAsCompleted',
    }),

    createPlayer() {
      const refPlayer = this.$refs.player;

      if (this.isYoutubeLink) {
        this.createPlayerYoutube(refPlayer);
      } else if (this.isRutubeLink) {
        this.createPlayerRutube(refPlayer);
      } else if (this.isVimeoLink) {
        this.createPlayerVimeo(refPlayer);
      } else {
        this.createPlayerPlyr(refPlayer);
      }
    },

    createPlayerRutube(refPlayer) {
      this.player = refPlayer;
      this.player.addEventListener('load', this.handleRutubeEvents);
    },

    createPlayerYoutube(refPlayer) {
      this.player = YouTubePlayer(refPlayer);
      this.player.on('stateChange', this.handleYoutubeEvents);
    },

    createPlayerVimeo(refPlayer) {
      this.player = new VimeoPlayer(refPlayer);
      this.player.on('play', () => {
        this.setStatisticStarted();
      });
      this.player.on('timeupdate', (event) => {
        if (event.percent >= this.completeThreshold) {
          this.setStatisticCompleted();
        }
      });
    },

    createPlayerPlyr(refPlayer) {
      this.player = new Plyr(refPlayer, {
        controls,
        i18n: {
          speed: 'Скорость',
          normal: 'Нормальная',
        },
        ratio: '16:9',
      });
      this.player.on('play', this.setStatisticStarted);
      this.player.on('ended', this.setStatisticCompleted);
    },

    async handleYoutubeEvents(event) {
      switch (event.data) {
        case -1:
          // unstarted
          this.player.duration = await event.target.getDuration();
          break;
        case 1:
          // playing
          this.setStatisticStarted();

          this.player.checkProgress = setInterval(async () => {
            const playTime = await event.target.getCurrentTime();
            if (playTime >= this.player.duration * this.completeThreshold) {
              this.setStatisticCompleted();

              clearInterval(this.player.checkProgress);
            }
          }, 1000);
          break;
        case 2:
          // paused
          clearInterval(this.player.checkProgress);
          break;

        // no default
      }
    },

    handleRutubeEvents() {
      window.addEventListener('message', this._handleRutubeEvents);
    },

    _handleRutubeEvents(e) {
      try {
        const message = JSON.parse(e.data);

        switch (message.type) {
          case 'player:durationChange':
            this.player.duration = message.data.duration;
            break;

          case 'player:changeState':
            if (message.data.state === 'playing') {
              this.setStatisticStarted();
            }
            break;
          case 'player:currentTime':
            if (message.data.time >= this.player?.duration * this.completeThreshold) {
              this.setStatisticCompleted();
            }
            break;

          // no default
        }

        // eslint-disable-next-line no-empty
      } catch (_) {}
    },

    setStatisticStarted() {
      mutex
        .runExclusive(async () => {
          if (
            this.activity.statistic.status !== ACTIVITY_STATUSES.doing
            && this.activity.statistic.status !== ACTIVITY_STATUSES.done
          ) {
            await this.setActivityAsStarted(this.activity._id);
          }
        });
    },

    setStatisticCompleted() {
      mutex
        .runExclusive(async () => {
          if (this.activity.statistic.status !== ACTIVITY_STATUSES.done) {
            await this.setActivityAsCompleted(this.activity._id);
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
