<template>
  <main class="main main-login">

    <div v-scrollanimation="{className: 'login--animated'}" class="login">
      <div class="content">
        <ResetPasswordCard
          :error="error"
          :loading="loading"
          @submit="setPassword"
        />
      </div>
    </div>

  </main>
</template>

<script>
import authorization from '@/api/authorization';
import ResetPasswordCard from '@/components/authorization/resetPassword/ResetPasswordCard.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',

  components: {
    ResetPasswordCard,
  },

  data() {
    return {
      error: null,
      loading: false,
    };
  },

  methods: {
    ...mapActions('user', [
      'onPasswordChanged',
      'signIn',
    ]),
    async setPassword(password) {
      this.error = null;
      this.loading = true;

      try {
        const data = {
          password,
          token: this.$route.query.token,
        };
        const response = await authorization.setPassword(data);
        const { email } = response?.data;

        /**
         * will send an email
         * @todo need to know email before fire this event
         * @see https://www.notion.so/cgnotion/d496a5fc8baa4cd49d74dec7aa6132b8
         */
        await this.signIn({ username: email, password });
        this.onPasswordChanged();

        await this.$router.push('/auth');
      } catch (error) {
        const status = error?.response?.status;
        if (status === 404) {
          this.error = 'Невалидная ссылка для восстановления пароля';
        } else if (status === 401) {
          this.error = 'Пароль должен содержать не менее 8-ми символов, минимум 1 заглавную, строчную букву, цифру, символ';
        } else {
          this.error = 'Что-то пошло не так...';
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/login.less";
</style>
