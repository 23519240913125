<template>
  <a :href="linkToSample" target="_blank" download class="link">
    Приложение к заданию
    <img src="@/assets/images/icons/icon__attachment--red.svg" width="14" height="14"
         alt="Вложение" class="link__icon">
  </a>
</template>

<script>

export default {
  name: 'LinkToSample',

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  computed: {
    linkToSample() {
      if (this.url) {
        return `https://${this.url}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>

</style>
