<template>
  <div class="breadcrumbs">
    <router-link
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      :to="breadcrumb.link"
      class="breadcrumbs__link red--text"
    >
      {{ breadcrumb.name }}
    </router-link>
    <div class="breadcrumbs__current">
      {{ currentBreadcrumb.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  props: {
    breadcrumbList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    breadcrumbs() {
      const breadcrumbs = [...this.breadcrumbList];
      breadcrumbs.pop();
      return breadcrumbs;
    },

    currentBreadcrumb() {
      return [...this.breadcrumbList].pop();
    },
  },
};
</script>

<style scoped>

</style>
