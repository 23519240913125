var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.items.length > 0)?_c('div',[(_vm.title)?_c('v-container',[_c('span',{staticClass:"text-h3 text-lg-h2 mr-2"},[_vm._v(_vm._s(_vm.title))]),_c('span',{staticClass:"red--text text-h3 text-lg-h2"},[_vm._v(_vm._s(_vm.items.length))])]):_vm._e(),_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{attrs:{"color":"transparent","max-width":"100vw"}},[_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('v-slide-group',{attrs:{"center-active":"","mobile-breakpoint":"700"},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('v-slide-item',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersect.bind(this, item, index),
              options: {
                threshold: 0.8,
              }
            }),expression:"{\n          handler: onIntersect.bind(this, item, index),\n              options: {\n                threshold: 0.8,\n              }\n            }"}],key:index,class:_vm.itemClass,scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_vm._t("default",null,{"item":item,"active":active,"toggle":toggle})]}}],null,true)})})},proxy:true}],null,false,1193885745)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }