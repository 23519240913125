<template>
  <div v-click-outside="hide" class="header-login">
    <div class='row justify-center'>
      <div class="hesk-button" :class="{'active':showForm}" @click="showForm = !showForm">
        Техническая поддержка
      </div>
    </div>
    <a class="header-login__button" @click="toggle">
      <div class="header-login__name">
        {{ user.first_name }}
      </div>
    </a>

    <div
      v-show="isVisible"
      class="header-login__dropdown"
    >
      <div class="close" @click="hide"></div>
      <div class="header-login__fullname">
        {{ user.last_name }} {{ user.first_name }}
      </div>
      <div class="header-login__email mb-1">{{ user.email }}</div>
      <v-btn
        text
        plain
        small
        :ripple="false"
        color="red"
        to="/my"
        class="pa-0 mb-2"
        @click="toggle"
      >
        <v-row no-gutters align="center">
          <v-icon>mdi-square-edit-outline</v-icon>
          <span class="ml-2">Мое обучение</span>
        </v-row>
      </v-btn>

      <div
        v-if="$vuetify.breakpoint.mobile"
        class="menu-login"
        @click="isVisible = !isVisible"
      >
        <router-link to="/" class="menu-login__link">Курсы</router-link>
        <router-link to="/about" class="menu-login__link">Об академии</router-link>
        <router-link to="/docs" class="menu-login__link">Документы</router-link>
      </div>

      <div class="menu-login">
        <a class="menu-login__link" @click="signOut">Выйти</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'MenuDropdown',

  directives: {
    ClickOutside,
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    ...mapState('user', {
      user: 'user',
    }),
  },

  methods: {
    async signOut() {
      await this.$store.dispatch('user/signOut');
      await this.$router.push('/auth');
    },

    hide() {
      this.isVisible = false;
    },

    toggle() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style scoped>
</style>
