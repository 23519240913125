import Vue from 'vue';

export default Vue.mixin({
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    coursesList() {
      throw new Error('implements coursesList');
    },
    tagList() {
      return this.coursesList.reduce((tags, course) => {
        const newTags = course.tags.filter((t) => !tags.find((tg) => tg.id === t.id));
        tags.push(...newTags);
        return tags;
      }, []);
    },
  },

  methods: {
    toggleTag(id) {
      const foundIndex = this.selectedTagsId.findIndex((i) => i === id);
      if (foundIndex > -1) {
        return this.selectedTagsId.splice(foundIndex, 1);
      }
      return this.selectedTagsId.push(id);
    },
  },
});
