import {
  HTTP,
  callApi,
} from './default';

const getAll = '?page=1&per_page=999';

export default {
  getCourses() {
    return HTTP({
      url: '/api/activity/all-courses',
      method: 'GET',
    });
  },

  getPublicCourses() {
    return HTTP({
      url: '/api/activity/public-courses',
      method: 'GET',
    });
  },

  resisterOnProgram(programId) {
    return HTTP({
      url: `/api/activity/signin/${programId}`,
      method: 'POST',
    });
  },

  getTask(taskId, activityContext) {
    return callApi({
      url: `/v1/student/activity/task/chat/${taskId}`,
      method: 'POST',
      data: {
        activity_context: activityContext,
      },
    });
  },

  sendFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return callApi({
      url: '/v1/fs/material',
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'PUT',
      data: formData,
    });
  },

  createChat(taskId, activityContext) {
    return callApi({
      url: '/v1/student/activity/task/chat',
      method: 'POST',
      data: {
        activity_id: taskId,
        activity_context: activityContext,
      },
    });
  },

  sendMessage(taskId, data) {
    return callApi({
      url: `/v1/student/activity/task/chat/${taskId}/message`,
      method: 'POST',
      data,
    });
  },

  getCourse(id, programId) {
    return callApi({
      url: `/v2/student/programs/${programId}/courses/${id}`,
      method: 'GET',
    });
  },

  getMaterials(programId, parentId) {
    return callApi({
      url: `/v1/student/activity/${programId}/with_parent_id/${parentId}${getAll}`,
      method: 'GET',
    });
  },

  getActivityTags(parentId, childId) {
    return callApi({
      url: `/v1/student/activity/${parentId}/${childId}/tag`,
      method: 'GET',
    });
  },

  setActivityAsStarted(materialId) {
    return callApi({
      url: `/v1/student/activity/${materialId}/set_started`,
      method: 'POST',
    });
  },

  setActivityAsCompleted(materialId) {
    return callApi({
      url: `/v1/student/activity/${materialId}/set_completed`,
      method: 'POST',
    });
  },

  setQuizAsStarted(activityId, statisticId) {
    return callApi({
      url: `/v1/student/activity/${activityId}/start_quiz`,
      method: 'POST',
      data: {
        parent_statistic_id: statisticId,
      },
    });
  },

  sendAnswers(questionId, data) {
    return callApi({
      url: `/v1/student/activity/${questionId}/submit_answers`,
      method: 'POST',
      data,
    });
  },

  getCertificate(activityId) {
    return callApi({
      url: `/v1/student/activity/${activityId}/certificate`,
      method: 'GET',
    });
  },
};
