<template>
  <v-row
    :ref="courseMaterialThemes"
    class="pa-0 ma-0 mt-2 justify-end flex-row"
  >
<!--
    <div
      v-for="({ _id, name, isActive }, index) in courseMaterialThemes"
      v-show="isShowButton(index)"
      :key="_id"
      :ref="_id"
    >
      <v-btn
        rounded
        class="mx-2 mb-2"
        :color="isActive && 'primary' || ''"
        :text="!isActive"
        @click="toggleActivity(_id)"
      >
        {{ name }}
      </v-btn>
    </div>

    <v-btn
      rounded
      @click="toggleIsShowButtonsInline"
    >
      {{ isShowButtonsInline ? `Ещё ${courseMaterialThemes.length - showButtonsCount}` : 'Меньше' }}
      <v-icon>mdi-chevron-{{ isShowButtonsInline ? 'down' : 'up' }}</v-icon>
    </v-btn>
-->
  </v-row>
</template>

<script>
export default {
  name: 'RcCourseMaterialTagFilters',
  data: () => ({
    isShowButtonsInline: false,
    courseMaterialThemes: [
      {
        _id: 1,
        name: 'Экономика продуктов',
        isActive: false,
      },
      {
        _id: 2,
        name: 'Бизнес-модели',
        isActive: false,
      },
      {
        _id: 3,
        name: 'Методология',
        isActive: true,
      },
      {
        _id: 4,
        name: 'Работа с целями',
        isActive: false,
      },
      {
        _id: 5,
        name: 'Работа с продуктом',
        isActive: false,
      },
      {
        _id: 6,
        name: 'Data-driven подход',
        isActive: false,
      },
      {
        _id: 7,
        name: 'Использование моделей на основе машинного обучения',
        isActive: false,
      },
      {
        _id: 8,
        name: 'Технологическая платформа ',
        isActive: false,
      },
    ],
    showButtonsCount: 0,
    isMounted: false,
  }),
  mounted() {
    this.isMounted = true;
  },
  methods: {
    isShowButton(index) {
      if (!this.isMounted) return true;
      if (this.isShowButtonsInline) return index < (this.showButtonsCount);
      return true;
    },
    calculateShowButtonsCount() {
      if (!this.isMounted) return;
      if (this.isShowButtonsInline) {
        this.showButtonsCount = 0;
        return;
      }
      const rowWidth = this.$el?.clientWidth || 0;
      const allowableWidth = rowWidth - 120;
      const buttons = Array.from(this.$el.children);
      let currentWidth = 0;
      let buttonCounter = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const btn of buttons) {
        if (currentWidth + (btn.clientWidth) > allowableWidth) break;
        buttonCounter += 1;
        currentWidth += btn.clientWidth;
      }
      this.showButtonsCount = buttonCounter;
    },
    toggleIsShowButtonsInline() {
      this.calculateShowButtonsCount();
      this.isShowButtonsInline = !this.isShowButtonsInline;
    },
    toggleActivity(_id) {
      const index = this.courseMaterialThemes.findIndex((el) => el._id === _id);
      if (index === -1) return;
      const courseMaterialTheme = {
        ...this.courseMaterialThemes[index],
        isActive: !this.courseMaterialThemes[index].isActive,
      };
      this.courseMaterialThemes.splice(index, 1, courseMaterialTheme);
    },
  },
};

</script>

<style scoped>

</style>
