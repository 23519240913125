<template>
  <div>
    <div
        ref="player"
        class="player-container"
        :class="{
          'player-container--visible': !loading,
          'player-container--full-screen': isFullscreenMode,
        }"
    >
      <LeraPdfPlayer
        v-if="url"
        :key="key"
        ref="playerLms"
        :src="url"
        class="player"
        :page="currentPage"
        :rerenderPage="rerenderPageTrigger"
        @page-rendered="loading = false"
        @page-loaded="setCurrentPage"
      />
      <div v-if="!loading" class="player-control">
        <div class="player-control__left">
          <PreviousSmallArrow @click="throttle(setPreviousPage)"/>
          <NextSmallArrow @click="throttle(setNextPage)"/>
          <input
              ref="input"
              v-model.number="inputValue"
              type="number"
              class="player-control__input"
              :max="pagesCounter"
              min="1"
              @keyup.enter="$refs.input.blur()"
          />
          <div v-show="pagesCounter" class="player-control__info-total-page">
            из {{ pagesCounter }}
          </div>
        </div>
        <div class="player-control__right">
          <FullScreenButton @click="toggleFullScreen"/>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="buttons">
      <a
          :href="fileLink"
          download
          target="_blank"
          class="link"
          @click="setActivityAsCompleted(activity._id)"
      >
        Скачать файл
        <img
            src="@/assets/images/icons/icon__attachment--red.svg"
            width="14"
            height="14"
            alt="Вложение"
            class="link__icon"
        >
      </a>
    </div>
  </div>
</template>

<script>
import FullScreenButton from '@/components/buttons/FullScreenButton.vue';
import NextSmallArrow from '@/components/buttons/NextSmallArrow.vue';
import PreviousSmallArrow from '@/components/buttons/PreviousSmallArrow.vue';
import fscreen from '@/utils/fullScreen';
import { mapActions, mapMutations } from 'vuex';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

const PDFJS = require('pdfjs-dist/es5/build/pdf.min');
// import { LeraPdfPlayer } from '@centergame/lera-vue-library';

export default {
  name: 'PdfPlayer',

  components: {
    PreviousSmallArrow,
    NextSmallArrow,
    FullScreenButton,
  },

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      throttleDelayMs: 0,
      time: Date.now(),
      src: null,
      isFullscreenMode: false,
      rerenderPageTrigger: 0,
      currentPage: 1,
      pagesCounter: 0,
      loading: true,
      url: '',
      fingerprint: '',
      worker: undefined,
      key: 'empty',
      // timeoutID: undefined,
    };
  },

  computed: {
    inputValue: {
      get() {
        return this.currentPage;
      },
      set(currentPage) {
        if (currentPage > 0 && currentPage <= this.pagesCounter) {
          this.currentPage = currentPage;
        }
      },
    },

    fileLink() {
      return `https://${this.activity.meta.url}`;
    },

    activityMeta() {
      return this.activity?.meta;
    },
  },

  watch: {
    'activityMeta.url': {
      immediate: true,
      async handler(newValue) {
        // no particular reason. Paranoidal remount pdf reader just in case
        this.key = newValue ? `file-${newValue}` : 'empty';

        this.$nextTick(() => {
          this.setLoaderActivity(true);
          this.refresh();
        });
      },
    },

    currentPage() {
      this.checkActivityAsCompleted();
    },
  },

  async created() {
    this.setLoaderActivity(true);
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener('fullscreenchange', this.handleFullscreenChange, false);
    }
    window.addEventListener('keydown', this.handleArrowKeys);
  },

  beforeDestroy() {
    this.worker.destroy();
    window.removeEventListener('keydown', this.handleArrowKeys);
    fscreen.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  },

  methods: {
    ...mapActions('viewer', {
      setActivityAsStarted: 'setActivityAsStarted',
      setActivityAsCompleted: 'setActivityAsCompleted',
    }),

    ...mapMutations('loader', {
      setLoaderActivity: 'setLoaderActivity',
    }),

    async refresh() {
      this.setCurrentPage(1);
      await new Promise((r) => setTimeout(r, 200));
      await this.getFileSrc();

      await this.checkActivityAsStarted();
      await this.checkActivityAsCompleted();
      this.setLoaderActivity(false);
    },

    async getFileSrc() {
      let url = this.activity?.meta?.url;
      if (!url) {
        this.url = '';
        return;
      }

      url = `https://${url}`;
      if (this.worker) {
        // destroy previous workers to prevent memory leaks
        this.worker.destroy();
      }

      this.worker = PDFJS.getDocument({ url });
      this.worker.__PDFDocumentLoadingTask = true;
      // this.worker = LeraPdfPlayer.createLoadingTask(url);

      const pdf = await this.worker.promise;
      this.pagesCounter = pdf._pdfInfo.numPages;
      this.fingerprint = pdf._pdfInfo.fingerprint;
      this.url = url;
    },

    toggleFullScreen() {
      if (this.isFullscreenMode) {
        fscreen.exitFullscreen();
      } else {
        const { player } = this.$refs;
        fscreen.requestFullscreen(player);
      }
    },

    setPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.scrollPageToTop();
      }
    },

    setNextPage() {
      if (this.currentPage < this.pagesCounter) {
        this.currentPage += 1;
        this.scrollPageToTop();
      }
    },

    throttle(callback) {
      if (this.time + this.throttleDelayMs - Date.now() < 0) {
        callback();
        this.time = Date.now();
      }
    },

    scrollPageToTop() {
      this.$nextTick(() => {
        const { player } = this.$refs;
        player.scrollTop = 0;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    },

    handleFullscreenChange() {
      if (fscreen.fullscreenElement !== null) {
        this.isFullscreenMode = true;
        this.$nextTick(() => (this.rerenderPageTrigger += 1));
      } else {
        this.isFullscreenMode = false;
      }
    },

    handleArrowKeys(e) {
      if (e.key === 'ArrowRight' && !this.loading) {
        e.preventDefault();
        this.throttle(this.setNextPage);
      }
      if (e.key === 'ArrowLeft' && !this.loading) {
        e.preventDefault();
        this.throttle(this.setPreviousPage);
      }
    },

    setCurrentPage(currentPage) {
      this.currentPage = currentPage;
    },

    async checkActivityAsStarted() {
      if (
        this.activity.statistic.status !== ACTIVITY_STATUSES.done
        && this.activity.statistic.status !== ACTIVITY_STATUSES.doing
      ) {
        await this.setActivityAsStarted(this.activity._id);
      }
    },

    async checkActivityAsCompleted() {
      if (this.activity.statistic.status !== ACTIVITY_STATUSES.done) {
        if (this.currentPage === this.pagesCounter) {
          await this.setActivityAsCompleted(this.activity._id);
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
