import Program from '@/api/program';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  namespaced: true,

  state: {
    materials: null,
    currentActivityId: null,
    currentCourseId: null,
  },

  mutations: {
    setCurrentActivityId(state, activityId) {
      state.currentActivityId = activityId;
    },

    setCurrentCourseId(state, courseId) {
      state.currentCourseId = courseId;
    },

    setMaterials(state, materials) {
      state.materials = materials;
    },

    setAsStarted(state, materialId) {
      state.materials = state.materials.map((material) => {
        if (material._id === materialId) {
          material.statistic.status = ACTIVITY_STATUSES.doing;
        }
        return material;
      });
    },

    setAsRejected(state, materialId) {
      state.materials = state.materials.map((material) => {
        if (material._id === materialId) {
          material.statistic.status = ACTIVITY_STATUSES.rejected;
        }
        return material;
      });
    },

    setAsCompleted(state, materialId) {
      state.materials = state.materials.map((material) => {
        if (material._id === materialId) {
          material.statistic.status = ACTIVITY_STATUSES.done;
        }
        return material;
      });
    },

    setNumberOfAttempts(state, { materialId, numberOfAttempts }) {
      state.materials = state.materials.map((material) => {
        if (material._id === materialId) {
          material.statistic.meta = {
            ...material.statistic.meta,
            used_attempts: numberOfAttempts,
          };
        }
        return material;
      });
    },

    setScore(state, { materialId, score }) {
      state.materials = state.materials.map((material) => {
        if (material._id === materialId) {
          material.statistic = {
            ...material.statistic,
            score,
          };
        }
        return material;
      });
    },
  },

  actions: {
    setCurrentCourse({ state, commit }, courserId) {
      if (state.currentCourseId !== courserId) {
        commit('setCurrentCourseId', courserId);
      }
    },

    setCurrentActivity({ state, commit }, activityId) {
      if (state.currentActivityId !== activityId) {
        commit('setCurrentActivityId', activityId);
      }
    },

    async getMaterials({ commit }, params) {
      try {
        const { programId } = params;
        const { parentId } = params;
        const response = await Program.getMaterials(programId, parentId);
        const materials = response.data;
        commit('setMaterials', materials);
      } catch (e) {
        console.log('e', e);
      }
    },

    async setActivityAsStarted({ commit }, materialId) {
      try {
        await Program.setActivityAsStarted(materialId);
        commit('setAsStarted', materialId);
      } catch (e) {
        console.log('e', e);
      }
    },

    async setActivityAsCompleted({ commit }, materialId) {
      try {
        await Program.setActivityAsCompleted(materialId);
        commit('setAsCompleted', materialId);
      } catch (e) {
        console.log('e', e);
      }
    },

    async setQuizAsStarted({ commit }, quiz) {
      try {
        await Program.setQuizAsStarted(quiz.activityId, quiz.statisticId);
        commit('setAsStarted', quiz.activityId);
      } catch (e) {
        console.log('e', e);
      }
    },
  },

  getters: {
    nextActivityId(state, getters) {
      const { currentActivityId } = getters;
      if (!currentActivityId) {
        return;
      }
      const currentIndex = state.materials.map((el) => el._id).indexOf(currentActivityId);
      const nextIndex = currentIndex + 1;
      // eslint-disable-next-line
      return state.materials[nextIndex]?._id;
    },
    prevActivityId(state, getters) {
      const { currentActivityId } = getters;
      if (!currentActivityId) {
        return;
      }
      const currentIndex = state.materials.map((el) => el._id).indexOf(currentActivityId);
      const prevIndex = currentIndex - 1;
      // eslint-disable-next-line
      return state.materials[prevIndex]?._id;
    },
    currentActivityId(state) {
      return state.currentActivityId || null;
    },

    currentCourseId(state) {
      return state.currentCourseId || null;
    },

    contestCoursesIdList() {
      const contestCoursesList = {
        stage: [],
        prod: [],
      };

      const isItAStage = !!(process.env.VUE_APP_ENV === 'staging' || !process.env.VUE_APP_ENV);
      return isItAStage ? contestCoursesList.stage : contestCoursesList.prod;
    },

    isItAContestCourseId(state, getters) {
      return getters.contestCoursesIdList.includes(getters.currentCourseId);
    },

    contestTasksIdList() {
      const contestTasksList = {
        stage: [
          '62a865bdbaf9a958d0944a7a',
          // '62aa2c2f694d23546243f005',
        ],
        prod: ['62a9a78f05f8e50532e7bda4'],
      };

      const isItAStage = !!(process.env.VUE_APP_ENV === 'staging' || !process.env.VUE_APP_ENV);
      return isItAStage ? contestTasksList.stage : contestTasksList.prod;
    },

    isItAContestIntroductionTask(state, getters) {
      return getters.contestTasksIdList.includes(getters.currentActivityId);
    },
  },
};
