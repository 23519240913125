const DADATA_URL = process.env?.VUE_APP_DADATA_URL
  || 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
const DADATA_TOKEN = process.env?.VUE_APP_DADATA_TOKEN
  || '1f5d3bf150686188a72ffb946b63a974108ec69b';
const DEFAULT_CITY = {
  data: {
    city: 'Москва',
    city_kladr_id: '7700000000000',
    region_kladr_id: '7700000000000',
  },
  value: 'г Москва',
};

const DEFAULT_CITIES_LIST = [
  DEFAULT_CITY,
  {
    data: {
      city: 'Архангельск',
      city_kladr_id: '2900000100000',
      region_kladr_id: '2900000000000',
    },
    value: 'г Архангельск',
  },
  {
    data: {
      city: 'Владивосток',
      city_kladr_id: '2500000100000',
      region_kladr_id: '2500000000000',
    },
    value: 'г Владивосток',
  },
  {
    data: {
      city: 'Воронеж',
      city_kladr_id: '3600000100000',
      region_kladr_id: '3600000000000',
    },
    value: 'г Воронеж',
  },
  {
    data: {
      city: 'Казань',
      city_kladr_id: '1600000100000',
      region_kladr_id: '1600000000000',
    },
    value: 'г Казань',
  },
  {
    data: {
      city: 'Краснодар',
      city_kladr_id: '2300000100000',
      region_kladr_id: '2300000000000',
    },
    value: 'г Краснодар',
  },
  {
    data: {
      city: 'Нижний Новгород',
      city_kladr_id: '5200000100000',
      region_kladr_id: '5200000000000',
    },
    value: 'г Нижний Новгород',
  },
  {
    data: {
      city: 'Петропавловск-Камчатский',
      city_kladr_id: '4100000100000',
      region_kladr_id: '4100000000000',
    },
    value: 'г Петропавловск-Камчатский',
  },
  {
    data: {
      city: 'Санкт-Петербург',
      city_kladr_id: '7800000000000',
      region_kladr_id: '7800000000000',
    },
    value: 'г Санкт-Петербург',
  },
  {
    data: {
      city: 'Саратов',
      city_kladr_id: '6400000100000',
      region_kladr_id: '6400000000000',
    },
    value: 'г Саратов',
  },
  {
    data: {
      city: 'Ярославль',
      city_kladr_id: '7600000100000',
      region_kladr_id: '7600000000000',
    },
    value: 'г Ярославль',
  },
];

/*
const REGIONS_LIST = [
  {
    region: 'Архангельская',
    region_kladr_id: '2900000000000',
    region_with_type: 'Архангельская обл',
  },
  {
    region: 'Приморский',
    region_kladr_id: '2500000000000',
    region_with_type: 'Приморский край',
  },
  {
    region: 'Воронежская',
    region_kladr_id: '3600000000000',
    region_with_type: 'Воронежская обл',
  },
  {
    region: 'Татарстан',
    region_kladr_id: '1600000000000',
    region_with_type: 'Респ Татарстан',
  },
  {
    region: 'Краснодарский',
    region_kladr_id: '2300000000000',
    region_with_type: 'Краснодарский край',
  },
  {
    region: 'Москва',
    region_kladr_id: '7700000000000',
    region_with_type: 'г Москва',
  },
  {
    region: 'Нижегородская',
    region_kladr_id: '5200000000000',
    region_with_type: 'Нижегородская обл',
  },
  {
    region: 'Камчатский',
    region_kladr_id: '4100000000000',
    region_with_type: 'Камчатский край',
  },
  {
    region: 'Санкт-Петербург',
    region_kladr_id: '7800000000000',
    region_with_type: 'г Санкт-Петербург',
  },
  {
    region: 'Саратовская',
    region_kladr_id: '6400000000000',
    region_with_type: 'Саратовская обл',
  },
  {
    region: 'Ярославская',
    region_kladr_id: '7600000000000',
    region_with_type: 'Ярославская обл',
  },
];
*/

export default {
  namespaced: true,

  state: () => ({
    query: '',
    suggestionsList: [],
    selectedCity: DEFAULT_CITY,
    queryPending: false,
  }),

  mutations: {
    setQuery(state, query) {
      state.query = query;
    },
    setSelectedCity(state, city) {
      state.selectedCity = city;
    },
    setSuggestionsList(state, list) {
      state.suggestionsList = list;
    },
    setQueryPending(state, value) {
      state.queryPending = value;
    },
  },

  actions: {
    async fetchSuggestions({ state, commit }) {
      commit('setQueryPending', true);

      const payload = {
        query: state.query || '',
        count: 5,
        from_bound: {
          value: 'city',
        },
        to_bound: {
          value: 'city',
        },
        locations_boost: [
          {
            kladr_id: DEFAULT_CITY.data.city_kladr_id,
          },
        ],
      };

      try {
        const response = await fetch(DADATA_URL, {
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${DADATA_TOKEN}`,
          },
        });
        const result = await response.json();
        commit('setSuggestionsList', result.suggestions);
      } catch (error) {
        console.log('DadataError', error);
      }

      commit('setQueryPending', false);
    },
  },

  getters: {
    suggestionsListComputed(state) {
      return state.suggestionsList?.length > 0 ? state.suggestionsList
        : DEFAULT_CITIES_LIST;
    },

    /*
    defaultRegion() {
      return REGIONS_LIST.find((e) => {
        const targetKladrId = DEFAULT_CITY.data.region_kladr_id;
        return targetKladrId === e.region_kladr_id;
      });
    },
    selectedRegion(state, getters) {
      const match = REGIONS_LIST.find((e) => {
        const targetKladrId = state.selectedCity?.data?.region_kladr_id;
        return targetKladrId === e.region_kladr_id;
      });
      return match || getters.defaultRegion;
    },
    */

    selectedLocationFormattedString(state) {
      const cityName = state.selectedCity.value || DEFAULT_CITY.value;
      // const regionName = getters.selectedRegion.data.region_with_type;
      return cityName;
    },
  },

};
