<template>
  <img
      src="@/assets/images/icons/icon__documents-2.svg"
      alt="Иконка"
      class="documents-card__icon"
  >
</template>

<script>
export default {
  name: 'HealthcareIcon',
};
</script>

<style scoped>

</style>
