import Vue from 'vue';

export default Vue.mixin({
  computed: {
    showForm: {
      get() {
        return this.$store.getters['hesk/getIsFormOpened'];
      },
      set(value) {
        this.$store.commit('hesk/setHeskFormState', value);
      },
    },
  },
});
