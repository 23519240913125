<template>
  <section class="section section--white section-join">
    <div class="content">
      <div class="join">
        <div class="join__content">
          <div class="section-header">
            <h2 class="section-title">Присоединяйся к команде Российского Красного Креста</h2>
            <div class="section-text">
              <p>Получи навыки, которые помогут спасать жизни, сохранять здоровье и обеспечивать
                безопасность людей.</p>
            </div>
          </div>
          <div class="buttons">
            <v-btn
                :to="isAuthenticated ? '/' : '/auth'"
                color="red"
                dark
                large
                :block="$vuetify.breakpoint.mobile"
            >
              {{ isAuthenticated ? 'Перейти к курсам' : 'Войти на платформу' }}
            </v-btn>
          </div>
          </div>
        <div class="join__pic"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SectionJoin',

  computed: {
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
    }),
  },
};
</script>

<style scoped>

</style>
