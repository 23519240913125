<template>
<div>
  <v-row
    v-for="link in linksList"
    :key="link.to"
    no-gutters
    :justify="justify"
  >
    <v-btn
      :to="link.to"
      class="text-body-1 font-weight-bold"
      text
      plain
      small
      :ripple="false"
    >
      {{ link.label }}
    </v-btn>
  </v-row>
</div>
</template>

<script>
export default {
  name: 'Menu',

  computed: {
    justify() {
      let result = 'start';
      if (this.$vuetify.breakpoint.mobile) {
        result = 'center';
      }
      return result;
    },
    linksList() {
      const result = [
        {
          to: '/',
          label: 'Главная',
        },
        {
          to: '/about',
          label: 'Об академии',
        },
        {
          to: '/docs',
          label: 'Документы',
        },
      ];
      return result;
    },
  },
};
</script>

<style scoped>

</style>
