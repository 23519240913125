<template>
  <router-link
    :to="materialUrl"
    class="part"
  >
    <div class="part__title">
      <component
        :is="fileIcon"
        class="part__icon"
      />
      {{ material.name }}
    </div>
    <component :is="statusIcon" class="part__status"></component>
  </router-link>
</template>

<script>
import ProgressDone from '@/components/icons/statuses/ProgressDone.vue';
import ProgressNotStarted from '@/components/icons/statuses/ProgressNotStarted.vue';
import ProgressDoing from '@/components/icons/statuses/ProgressDoing.vue';
import StatusFail from '@/components/icons/statuses/StatusFail.vue';
import FileIcon from '@/components/icons/types/File.vue';
import PdfIcon from '@/components/icons/files/PdfIcon.vue';
import ListIcon from '@/components/icons/types/List.vue';
import VideoIcon from '@/components/icons/types/Video.vue';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'Material',

  props: {
    material: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    statusIcon() {
      const { status } = this.material.statistic;

      switch (status) {
        case ACTIVITY_STATUSES.done:
          return ProgressDone;
        case ACTIVITY_STATUSES.notStarted:
          return ProgressNotStarted;
        case ACTIVITY_STATUSES.rejected:
          return StatusFail;
        default:
          return ProgressDoing;
      }
    },

    fileIcon() {
      if (this.material.meta?.fileExtension === 'pptx') return FileIcon;
      if (this.material.meta?.fileExtension === 'pdf') return PdfIcon;
      if (this.material.meta?.fileExtension === 'docx') return FileIcon;
      if (this.material.type === 'video') return VideoIcon;
      return ListIcon;
    },

    materialUrl() {
      const { programId } = this.$route.params;
      const courseId = this.$route.params.id;
      const id = this.material.parent_id;

      return `/viewer/${id}/${programId}/${courseId}?activityId=${this.material._id}`;
    },
  },
};
</script>

<style scoped>
.part__icon {
  height: 25px;
}
</style>
