import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import RegisterOnProgram from './registerOnProgram';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'DefaultLayout',
    redirect: '/',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: [
      {
        path: '/',
        name: 'Main',
        component: () => import('../views/Main.vue'),
        meta: {
          title: 'Главная',
        },
      },
      {
        path: '/my/',
        name: 'Profile',
        component: () => import('../views/my/index.vue'),
        meta: {
          requiresAuth: true,
          title: 'Личный кабинет',
        },
      },
      // {
      //   path: '/news/:slug',
      //   name: 'NewsPage',
      //   component: () => import('../views/NewsPage.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     title: 'Новость'
      //   },
      // },
      {
        path: '/course-user/:id/:programId',
        name: 'Course',
        beforeEnter: (to, from, next) => {
          const { id } = to.params;
          store.dispatch('viewer/setCurrentCourse', id);
          next();
        },
        component: () => import('../views/Course.vue'),
        meta: {
          requiresAuth: true,
          title: 'Курс',
        },
      },
      {
        path: '/public-course/:id/',
        name: 'Public course',
        beforeEnter: async (to, from, next) => {
          const { id } = to.params;
          if (!store.state.courses.publicCoursesList.length) {
            await store.dispatch('courses/fetchPublicCoursesList');
          }
          const currentCourse = store.state.courses.publicCoursesList.find((i) => i._id === id);
          if (!currentCourse) {
            next('/page-not-found');
          }
          next();
        },
        component: () => import('../views/PublicCourse.vue'),
        meta: {
          title: 'Курс',
        },
      },
      {
        path: '/viewer/:parentId/:programId/:courseId',
        name: 'Viewer',
        beforeEnter: (to, from, next) => {
          const { courseId } = to.params;
          store.dispatch('viewer/setCurrentCourse', courseId);
          next();
        },
        component: () => import('../views/Viewer.vue'),
        meta: {
          requiresAuth: true,
          title: 'Просмотр материала',
        },
      },
      {
        path: '/docs',
        name: 'Documents',
        component: () => import('../views/Documents.vue'),
        meta: {
          requiresAuth: true,
          title: 'Документы',
        },
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
        meta: {
          title: 'Об академии',
        },
      },
    ],
  },
  {
    path: '',
    name: 'AuthLayout',
    component: () => import('../layouts/AuthLayout.vue'),
    children:
      [
        {
          path: '/auth',
          name: 'Authorization',
          component: () => import('../views/authorization/Authorization.vue'),
          meta: {
            title: 'Авторизация',
          },
        },
        {
          path: '/register',
          name: 'Registration',
          component: () => import('../views/authorization/Registration.vue'),
          meta: {
            title: 'Регистрация',
          },
        },
        {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: () => import('../views/authorization/ForgotPassword.vue'),
          meta: {
            title: 'Забыли пароль?',
          },
        },
        {
          path: '/reset-password',
          name: 'ResetPassword',
          component: () => import('../views/authorization/ResetPassword.vue'),
          meta: {
            title: 'Сброс пароля',
          },
        },
      ],
  },
  {
    path: '/signin/:programId',
    beforeEnter: RegisterOnProgram,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/page-not-found',
    component: () => import('../views/E404.vue'),
    meta: {
      title: '404',
    },
  },
  {
    path: '*',
    beforeEnter:
      (to, from, next) => {
        next('/page-not-found');
      },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { activityId } = to.query;
  store.dispatch('viewer/setCurrentActivity', activityId);

  if (to.meta.title) {
    document.title = `${to.meta.title} | Академия Красного Креста`;
  } else {
    document.title = 'Академия Красного Креста';
  }

  if (to.meta.requiresAuth) {
    if (!store.getters['user/isAuthenticated']) {
      localStorage.setItem('redirectUrl', to.fullPath);
      next('/auth');
    }
  }

  if (store.getters['user/isAuthenticated']) {
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl');
      next(redirectUrl);
    }
  }

  if (to.matched.some((m) => m.name === 'AuthLayout')) {
    if (store.getters['user/isAuthenticated']) {
      next('/');
    }
  }

  next();
});

router.afterEach(() => {
  setTimeout(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
});

export default router;
