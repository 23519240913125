import Vue from 'vue';
import Swal from 'sweetalert2';

export default Vue.mixin({
  methods: {
    showError({ title = '', message = 'Что-то пошло не так...' }) {
      Swal.fire({
        position: 'bottom-end',
        title,
        text: message,
        color: '#ffffff',
        showConfirmButton: false,
        showCloseButton: true,
        toast: true,
        background: '#eb5757',
        timerProgressBar: true,
        timer: 3000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    },
    showMessage({
      title = '', message = '', color = 'white', background = '#FFC107',
    }) {
      Swal.fire({
        position: 'bottom-end',
        title,
        text: message,
        color,
        showConfirmButton: false,
        showCloseButton: true,
        toast: true,
        background,
        timerProgressBar: true,
        timer: 3000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    },
  },
});
