<template>
  <form class="login__form" @submit.prevent="submit">
    <label class="field">
      <EducationSelector v-model="education"  autofocus/>
      <span
        v-if="$v.form.education.$dirty && !$v.form.education.required"
        class="field__error"
      >
                Заполните поле
              </span>
    </label>
    <label class="field">
      <input
        v-model="university"
        type="text"
        class="field__value field__value--placeholder"
        placeholder="Введите полное название"
      >
      <span class="field__label">Учебное заведение </span>
      <span
        v-if="$v.form.university.$dirty && !$v.form.university.required"
        class="field__error"
      >
                Заполните поле
              </span>
    </label>
    <label class="field">
      <geo-selector/>
      <span
        v-if="$v.form.city.$dirty && !$v.form.city.required"
        class="field__error"
      >
                Заполните поле
              </span>
    </label>
    <label class="field">
      <input
        v-model="phone"
        v-mask="'+7 (###) ###-##-##'"
        type="tel"
        class="field__value field__value--placeholder"
        placeholder="+7 (ххх) ххх-хх-хх"
        @focusin="onFocusPhone"
      >
      <span class="field__label">Телефон</span>
      <span
        v-if="$v.form.phone.$dirty && !$v.form.phone.minLength"
        class="field__error"
      >
                Некорректный номер телефона
              </span>
      <span
        v-else-if="error"
        class="field__error"
      >
                {{ error }}
              </span>
    </label>
    <v-btn
      v-if="!loading"
      color="red"
      dark
      large
      block
      type="submit"
    >
      Зарегистрироваться
    </v-btn>
    <button v-else class="button button--full button--disabled button--preloader"></button>
  </form>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import GeoSelector from '@/components/geolocation/GeoSelector.vue';
import EducationSelector from '@/views/authorization/EducationSelector.vue';

export default {
  name: 'SecondStepForm',

  components: {
    GeoSelector,
    EducationSelector,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      education: '',
      university: '',
      city: '',
      phone: '',
    };
  },

  computed: {
    ...mapGetters('geolocation', [
      'selectedLocationFormattedString',
    ]),
    form() {
      return {
        education: this.education,
        university: this.university,
        city: this.selectedLocationFormattedString,
        phone: this.phone.replace(/[ ()-]/g, ''),
      };
    },
  },

  validations: {
    form: {
      education: {
        required,
      },
      university: {
        required,
      },
      city: {
        required,
      },
      phone: {
        minLength: minLength(12),
      },
    },
  },

  methods: {
    submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.$emit('submit', this.form);
      }
    },

    onFocusPhone() {
      if (!this.phone) {
        this.phone = '+7';
      }
    },
  },
};
</script>

<style scoped>

</style>
