import courses from './courses';
import user from './user';
import viewer from './viewer';
import loader from './loader';
import geolocation from './geolocation';
import hesk from './hesk';

export default {
  courses,
  user,
  viewer,
  loader,
  geolocation,
  hesk,
};
