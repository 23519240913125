import Program from '@/api/program';
import store from '@/store';

export default async (to, from, next) => {
  const { programId } = to.params;

  if (store.getters['user/isAuthenticated']) {
    try {
      await Program.resisterOnProgram(programId);
      next('/');
    } catch {
      next('/page-not-found');
    }
  }
};
