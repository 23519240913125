<template>
  <div class="loader" :style="loaderStyle">
    <div class="preloader-triple"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  computed: {
    loaderStyle() {
      const systemBarHeight = this.$vuetify.application.top;
      const headerHeight = this.$vuetify.application.bar;
      const footerHeight = this.$vuetify.breakpoint.mobile ? 449 : 240;
      const delta = footerHeight + headerHeight + systemBarHeight;
      return {
        height: `calc(100vh - ${delta}px)`,
      };
    },
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  /*
  height: 100%;
  */
}
</style>
