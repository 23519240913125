<template>
  <div class="testing__panel">
    <div class="testing-result">
      <h3 class="block-pretitle">{{ testingResultTitle }}</h3>
      <div v-if="activity.max_score" class="testing-result__number">
        <span
            :class="{
              'text-success': isDone,
              'text-error': isRejected,
            }">
          {{ score }}
        </span>
        из {{ activity.max_score }} баллов
        <div v-if="isRejected" class="testing-result__notice">
          для прохождения нужно набрать не менее {{ activity.meta.passing_score}} баллов
        </div>
      </div>
      <div v-else class="testing-result__number">
        <span>{{ correctAnswers }}/{{ quiz.length }}</span>
      </div>
      <img
          v-if="isDone"
          src="@/assets/images/icons/icon__status--success.svg"
          alt="Иконка"
          class="testing-result__icon"
      >
      <img
          v-else
          src="@/assets/images/icons/icon__status--fail.svg"
          alt="Иконка"
          class="testing-result__icon"
      >
      <h3 class="block-subtitle">
        Вы ответили правильно на {{ correctAnswers }} из {{ quiz.length }}.
        <span v-if="correctAnswers !== quiz.length">
          Посмотрите ответы, в которых вы ошиблись.
        </span>
      </h3>
      <div class="buttons">
        <div class="button-wrap">
          <a class="button button--hollow" @click="$emit('viewAnswers')">Посмотреть ответы</a>
        </div>
        <div v-if="isAvailableForReplay" class="button-wrap">
          <a class="button" @click="$emit('replayActivity')">Пройти еще раз</a>
          <div v-if="availableAttempts > 0 " class="button-notice">
            У вас есть еще {{ formattedAttempts }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNoun } from '@/core/helpers/getNoun';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'QuizResult',

  props: {
    activity: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Array,
      required: true,
    },
  },

  computed: {
    correctAnswers() {
      const questions = this.quiz;

      let missedQuestions = 0;
      questions.map((question) => {
        if (question.statistic.meta) {
          if (question.statistic.meta.answers.find((x) => x.is_correct !== x.is_selected)) {
            missedQuestions += 1;
          }
        }
        return question;
      });
      return questions.length - missedQuestions;
    },

    availableAttempts() {
      const usedAttempts = this.activity.statistic?.meta?.used_attempts || 0;
      const { attempts } = this.activity.meta;
      return attempts - usedAttempts;
    },

    isAvailableForReplay() {
      if (this.correctAnswers === this.quiz.length) return false;

      const { attempts } = this.activity.meta;

      if (!attempts) return true;

      return !!this.availableAttempts;
    },

    formattedAttempts() {
      const attempts = this.availableAttempts;

      return `${attempts} ${getNoun(attempts, 'попытка', 'попытки', 'попыток')}`;
    },

    testingResultTitle() {
      let testingResultTitle = '';

      if (this.isDone) {
        testingResultTitle = 'Вы прошли тест';
      } else {
        testingResultTitle = 'Вы не прошли тест';
      }
      if (this.activity.max_score) {
        testingResultTitle += ' и набрали';
      }

      return testingResultTitle;
    },

    score() {
      return this.activity.statistic?.score;
    },

    status() {
      return this.activity.statistic?.status;
    },

    isDone() {
      return this.status === ACTIVITY_STATUSES.done;
    },

    isRejected() {
      return this.status === ACTIVITY_STATUSES.rejected;
    },
  },
};
</script>

<style scoped>

</style>
