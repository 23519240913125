<template>
  <div class="tag-filter">
    <div name="list-tag" class="tags-select__control">
      <TagBadge
        v-for="tag in tagsToShow"
        :key="tag.id"
        :title="tag.name"
        :tag="tag"
        :selectedTagsId="selectedTagsId"
        :isEditable="false"
        isDisabled
        @click="(id) => $emit('clickedBadge', id)"
      />

      <TagBadge
        v-if="hiddenTagsCount > 0 || areAllTagsVisible"
        key="more"
        variant="secondary"
        :title="showMoreButtonLabel"
        :isEditable="false"
        class="cursor-pointer"
        :selectedTagsId="selectedTagsId"
        @click="areAllTagsVisible = !areAllTagsVisible"
      >
        <template #suffixIcon>
          <svg
            :class="{'show': areAllTagsVisible}"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 3.75L6 8.25L1.5 3.75"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>
      </TagBadge>
    </div>
  </div>
</template>

<script>
import deviceMixin from '@/mixins/device.mixin';
import TagBadge from '@/components/Tag/TagBadge.vue';

export default {
  components: {
    TagBadge,
  },
  mixins: [deviceMixin],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    selectedTagsId: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOverflowing: false,
      areAllTagsVisible: false,
    };
  },
  computed: {
    tagsToShowCount() {
      return this.tags.reduce(
        (summaries, tag) => {
          if (summaries.summaryWidth < (this.$device.isMobile ? 343 : 1060)) {
            // ~5.7px to 1 symbol on mobile and ~14px to 1 symbol on desktop
            summaries.summaryWidth += tag.name.length * (this.$device.isMobile ? 5.7 : 14);
            summaries.count += 1;
          }
          return summaries;
        },
        { summaryWidth: 0, count: 0 },
      ).count;
    },
    tagsToShow() {
      return this.areAllTagsVisible ? this.tags : this.tags.slice(0, this.tagsToShowCount);
    },
    hiddenTagsCount() {
      return this.tags.length - this.tagsToShow.length;
    },
    showMoreButtonLabel() {
      return this.areAllTagsVisible ? 'Свернуть' : 'Показать еще ';
    },
    icon() {
      return !this.areAllTagsVisible ? 'chevron-down' : 'chevron-up';
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-select__control {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.25rem 0;
}
</style>
