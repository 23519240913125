<template>
  <div class="hesk" :class="{ opened: showForm }">
    <v-dialog v-model="showForm" width="100%" max-width="422px">
      <HeskForm :key="showForm" :closeForm="closeForm" :options='categories' @close="closeForm" />
    </v-dialog>
  </div>
</template>

<script>
import HeskForm from '@/components/hesk/HeskForm.vue';
import deviceMixin from '@/mixins/device.mixin';
import heskMixin from '@/mixins/hesk.mixin';
import HeskService from '@/api/hesk';
import HESK_CATEGORIES from '@/core/constants/heskCatgories';

export default {
  name: 'Hesk',
  components: { HeskForm },
  mixins: [deviceMixin, heskMixin],
  data: () => ({
    scrollY: 0,
    categories: null,
  }),
  watch: {
    showForm(isShown) {
      if (!this.$device.isMobile) {
        return;
      }
      if (isShown) {
        this.scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${this.scrollY}px`;
      } else {
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(this.scrollY, 10));
      }
    },
  },
  async created() {
    await this.mapHeskCategories();
  },
  methods: {
    closeForm() {
      this.showForm = false;
    },
    async mapHeskCategories() {
      try {
        const categories = await HeskService.fetchCategories();
        this.categories = categories
          .map((category) => {
            const foundCategory = HESK_CATEGORIES.find((hc) => category.name
              .trim()
              .toLocaleLowerCase() === hc.name.toLocaleLowerCase());

            if (foundCategory) {
              return {
                id: category.id,
                text: category.name,
                description: foundCategory.description,
              };
            }

            return null;
          })
          // clear all null
          .filter(Boolean);
      } catch (e) {
        console.log('Не удалось получить категории для тех поддержки', e);
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/assets/styles/pages/hesk.less';
</style>
