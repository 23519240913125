<template>
  <div class="page pt-10">
    <main class="main main-error">
      <div class="section section-error">
        <div class="content">
          <div class="error">
            <div class="error__title">404</div>
            <div class="error__text">Страница не найдена</div>
            <v-btn
              dark
              to="/"
              color="red"
              large
              width="174"
              class="text-h3"
            >
              На главную
            </v-btn>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'E404',
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/error.less";
</style>

<style lang="scss" scoped>
.v-application .error {
  background-color: unset !important;
  border-color: unset !important;
}
</style>
