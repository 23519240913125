import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,

  state: {
  },
  mutations: {
  },
  actions: {
    /**
     * Executes before application render
     */
    async appStart({ dispatch }) {
      await dispatch('user/getUserInfo');
      await dispatch('courses/initCourses');
    },
  },
  getters: {
    /**
     * product, staging or undefined
     */
    appMode() {
      return process.env.VUE_APP_ENV;
    },
    isDev(state, getters) {
      return getters.appMode === 'staging' || !getters.appMode;
    },
  },
});
