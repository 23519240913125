<template>
  <div class="login__panel">
    <div class="login__header">
      <AuthorizationTabs />
    </div>
    <form class="login__form" @submit.prevent="signInForm">
      <label class="field">
        <input
          v-model.trim="form.email"
          :class="{'field__value--error': $v.form.email.$dirty && $v.form.email.$invalid}"
          type="email"
          class="field__value"
          placeholder="Электронная почта"
          autofocus
        >
        <span class="field__label">Электронная почта</span>
        <span
          v-if="$v.form.email.$dirty && !$v.form.email.required"
          class="field__error"
        >
                Заполните поле
              </span>
        <span
          v-else-if="$v.form.email.$dirty && !$v.form.email.email"
          class="field__error"
        >
                Некорректный E-mail
              </span>
      </label>
      <label class="field">
        <input
          v-model="form.password"
          :class="{
                  'field__value--error': $v.form.password.$dirty && $v.form.password.$invalid
                }"
          type="password"
          class="field__value"
          placeholder="Пароль"
        >
        <span class="field__label">Пароль</span>
        <span
          v-if="$v.form.password.$dirty && !$v.form.password.required"
          class="field__error"
        >
                Заполните поле
              </span>
        <span v-else-if="error" class="field__error">
                {{ error }}
              </span>
      </label>
      <div class="login__button">
        <v-btn v-if="!loading" color="red" large dark block type="submit">Войти</v-btn>
        <button v-else class="button button--disabled button--preloader v-btn--block"></button>
      </div>
    </form>
    <div class="login__notice">
      Забыли пароль?
      <router-link to="/forgot-password" class="link">Восстановить</router-link>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import AuthorizationTabs from '@/components/authorization/AuthorizationTabs.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LoginCard',

  components: {
    AuthorizationTabs,
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error: '',
    };
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  computed: {
    ...mapState('user', [
      'signInPending',
      'signInErrorMessage',
    ]),
    loading() {
      return this.signInPending;
    },
  },

  methods: {
    ...mapActions('user', [
      'signIn',
    ]),
    /** @todo this should be inside a store */
    async signInForm() {
      this.$v.form.$touch();
      this.error = null;
      if (!this.$v.$invalid) {
        const { email: username, password } = this.form;
        const loginSuccess = await this.signIn({ username, password });
        if (loginSuccess) {
          await this.$router.push('/');
        }
      }
      this.error = this.signInErrorMessage;
    },
  },
};
</script>

<style scoped>

</style>
