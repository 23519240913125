<template>
  <div class="login__panel">
    <div class="login__content">
      <div class="block-title">Восстановление пароля</div>
    </div>
    <div class="login__form">
      <label class="field">
        <input
          v-model="form.password"
          type="password"
          autocomplete="new-password"
          class="field__value"
          placeholder="Придумайте новый пароль"
        >
        <span class="field__label">Придумайте новый пароль</span>
        <span
          v-if="$v.form.password.$dirty && !$v.form.password.required"
          class="field__error"
        >
                Заполните поле
              </span>
        <span
          v-else-if="$v.form.password.$dirty && !$v.form.password.passwordMatch"
          class="field__error"
        >
               Пароль должен содержать не менее 8-ми символов,
                минимум 1 заглавную, строчную букву, цифру, символ
              </span>
      </label>
      <label class="field">
        <input
          v-model="form.passwordRepeat"
          type="password"
          autocomplete="new-password"
          class="field__value"
          placeholder="Повторите пароль"
        >
        <span class="field__label">Повторите пароль</span>
        <span
          v-if="$v.form.passwordRepeat.$dirty && !$v.form.passwordRepeat.required"
          class="field__error"
        >
                Заполните поле
              </span>
        <span
          v-else-if="$v.form.passwordRepeat.$dirty && !$v.form.passwordRepeat.sameAsPassword"
          class="field__error"
        >
                Пароли не совпадают
              </span>
        <span
          v-else-if="error"
          class="field__error"
        >
                {{ error }}
              </span>
      </label>
      <div class="login__button">
        <button v-if="!loading" class="button" @click="submit">Восстановить</button>
        <button v-else class="button button--disabled button--preloader"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs, helpers } from 'vuelidate/lib/validators';
import { passwordRegexp } from '@/core/constants/regexp';

const passwordMatch = helpers.regex('passwordMatch', passwordRegexp);

export default {
  name: 'ResetPasswordCard',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: {
        password: '',
        passwordRepeat: '',
      },
    };
  },

  validations: {
    form: {
      password: {
        required,
        passwordMatch,
      },
      passwordRepeat: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  methods: {
    submit() {
      this.$v.form.$touch();

      if (!this.$v.$invalid) {
        this.$emit('submit', this.form.password);
      }
    },
  },
};
</script>

<style scoped>

</style>
