<template>
  <main class="main main-login fill-height">
    <div v-scrollanimation="{className: 'login--animated'}" class="login">
      <div class="content">
        <LoginCard />
      </div>
    </div>
  </main>
</template>

<script>
import LoginCard from '@/components/authorization/login/LoginCard.vue';

export default {
  name: 'Authorization',

  components: {
    LoginCard,
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/login.less";
</style>
