<template>
  <div>
    <SectionDiagnostic />
    <RcAnimate>
      <v-container ref="coursesTitle">
        <RcPageTitle> Курсы Академии </RcPageTitle>
        <rc-course-material-tag-filters />
      </v-container>

      <v-container v-if="tagList.length">
        <h1 class="mb-3">Выберите тему</h1>
        <TagFilter
          v-if="tagList.length"
          :tags="tagList"
          :selectedTagsId="selectedTagsId"
          @clickedBadge="toggleTag"
        />
      </v-container>

      <RcItemsList
        :items="filteredCoursesByTags"
        :item-height="$vuetify.breakpoint.mdAndDown ? 450 : 470"
        :height="`calc(100vh - ${$vuetify.application.bar + $vuetify.application.top}px)`"
        :full-height="true"
        @pageChange="scrollTo"
      >
        <template v-slot:default="{ item }">
          <RcItemCard
            :key="item._id"
            :activity="item"
            :to="`/course-user/${item._id}/${item.parent_id}`"
            class="pb-4"
            image-label="Курс"
          />
        </template>
      </RcItemsList>
    </RcAnimate>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import RcAnimate from '@/components/rc/RcAnimate.vue';

import RcPageTitle from '@/components/rc/RcPageTitle.vue';
import RcCourseMaterialTagFilters from '@/components/rc/RcCourseMaterialTagFilters.vue';
import RcItemCard from '@/components/rc/RcItemCard.vue';
import RcItemsList from '@/components/rc/RcItemsList.vue';
import SectionDiagnostic from '@/components/mainGuest/sections/SectionDiagnostic.vue';
import TagFilter from '@/components/Tag/TagFilter.vue';
import tagsMixin from '@/mixins/tags.mixin';

export default {
  name: 'RcCourseCatalog',

  components: {
    TagFilter,
    RcItemCard,
    RcCourseMaterialTagFilters,
    RcItemsList,
    RcPageTitle,
    RcAnimate,
    SectionDiagnostic,
  },

  mixins: [tagsMixin],

  data: () => ({
    courses: [],
    selectedTagsId: [],
    page: 1,
  }),

  computed: {
    ...mapGetters('courses', ['coursesList']),
    filteredCoursesByTags() {
      if (!this.selectedTagsId.length) {
        return this.coursesList;
      }
      return this.coursesList
        .filter((course) => this.selectedTagsId.some((tagId) => course.tag_ids.includes(tagId)));
    },
  },

  created() {
    this.initCourses();
  },

  methods: {
    ...mapActions('courses', ['initCourses', 'fetchCoursesList']),
    scrollTo() {
      this.$refs.coursesTitle.scrollIntoView({
        behavior: 'auto',
      });
    },
  },
};
</script>
