<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M6.58355 1.83594H3.3928C2.82863 1.83594 2.28758
                    2.06006 1.88866 2.45901C1.48974 2.85796 1.26563 3.39905
                    1.26562 3.96325V7.15422M20.4102 7.15422V3.96325C20.4102
                    3.39905 20.186 2.85796 19.7871 2.45901C19.3882 2.06006
                    18.8471 1.83594 18.283 1.83594H15.0922M15.0922
                    20.9818H18.283C18.8471 20.9818 19.3882 20.7576 19.7871
                    20.3587C20.186 19.9597 20.4102 19.4186 20.4102
                    18.8544V15.6635M1.26562 15.6635V18.8544C1.26563 19.4186
                    1.48974 19.9597 1.88866 20.3587C2.28758 20.7576 2.82863
                    20.9818 3.3928 20.9818H6.58355"
      stroke="#F2F2F2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'FullScreenButton',
};
</script>
