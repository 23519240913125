<template>
  <div class="login__tabs">
    <router-link
      to="/auth"
      class="login__tab"
      :class="{'login__tab--active': $route.path === '/auth'}"
    >
      Вход
    </router-link>
    <router-link
      to="/register"
      class="login__tab"
      :class="{'login__tab--active': $route.path === '/register'}"
    >
      Регистрация
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AuthorizationTabs',
};
</script>

<style scoped>

</style>
