import Vue from 'vue';

export default Vue.mixin({
  data() {
    return {
      deviceWidth: document.body.clientWidth,
    };
  },

  computed: {
    $device() {
      return {
        isMobile: this.deviceWidth <= 767,
        isTablet: (this.deviceWidth > 767) && (this.deviceWidth < 1300),
        isDesktop: this.deviceWidth >= 1300,
      };
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.deviceWidth = document.body.clientWidth;
    },
  },
});
