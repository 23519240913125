<template>
  <img
    src="@/assets/images/icons/icon__status--current.svg"
    alt="Активный"
  >
</template>

<script>
export default {
  name: 'ProgressDoing',
};
</script>

<style scoped>

</style>
