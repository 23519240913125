<template>
  <transition name="fade">
    <template v-if="!loading">
      <QuizResult
          v-if="showResult"
          :activity="activity"
          :quiz="quiz"
          @viewAnswers="viewAnswers"
          @replayActivity="replayActivity"
      />
      <div v-else-if="question" class="testing__panel">
        <QuizHeader
            :questionNumber="questionIndex + 1"
            :questionsQuantity="quiz.length"
            :questionText="question.name"
        />

        <QuizCard
            :question="question"
            @change="selectAnswer"
        />

        <div class="testing__footer">
          <button
              v-if="questionIndex > 0"
              class="button button--hollow"
              @click="selectQuestion(questionIndex - 1)"
          >
            Предыдущий вопрос
          </button>

          <button
              v-if="!isLastQuestion && isQuestionCompleted"
              class="button button--right"
              @click="selectQuestion(questionIndex + 1)"
          >
            Следующий вопрос
          </button>

          <button
              v-if="isLastQuestion && isQuestionCompleted"
              class="button button--right"
              @click="showResult = true"
          >
            Посмотреть результат
          </button>

          <v-spacer v-if="!isQuestionCompleted" />

          <button
              v-if="!isQuestionCompleted"
              class="button"
              :class="{'button--hollow button--disabled': !isSubmitAvailable || answerPending}"
              @click="submitAnswers"
          >
            Отправить ответ
          </button>
        </div>
      </div>
      <QuizEmpty v-else-if="!quiz.length"/>
    </template>
  </transition>
</template>

<script>
import Program from '@/api/program';
import QuizHeader from '@/components/viewer/quizViewer/QuizHeader.vue';
import QuizCard from '@/components/viewer/quizViewer/QuizCard.vue';
import QuizResult from '@/components/viewer/quizViewer/QuizResult.vue';
import QuizEmpty from '@/components/viewer/quizViewer/QuizEmpty.vue';
import { mapActions, mapMutations } from 'vuex';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'QuizViewer',

  components: {
    QuizHeader,
    QuizCard,
    QuizResult,
    QuizEmpty,
  },

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      quiz: null,
      question: null,
      questionIndex: 0,
      showResult: false,
      loading: true,
      answerPending: false,
    };
  },

  computed: {
    selectedAnswers() {
      return this.question.meta.answers
        .filter((answer) => answer.is_selected)
        .map(((answer) => answer.id));
    },

    isQuestionCompleted() {
      return this.question.statistic.status === ACTIVITY_STATUSES.done;
    },

    isSubmitAvailable() {
      return Boolean(this.selectedAnswers.length) && !this.isQuestionCompleted;
    },

    isLastQuestion() {
      return this.questionIndex + 1 === this.quiz.length;
    },

    score() {
      let score = 0;
      const questions = this.quiz;

      questions.forEach((question) => {
        if (!question.statistic.meta.answers.find((x) => x.is_correct !== x.is_selected)) {
          score += question.max_score;
        }
      });
      return score;
    },
  },

  async created() {
    this.setLoaderActivity(true);
    await this.getQuiz();

    if (this.activity.statistic.status === ACTIVITY_STATUSES.done
        || this.activity.statistic.status === ACTIVITY_STATUSES.rejected) {
      this.showResult = true;
    } else {
      this.questionIndex = this.getFirstUncompletedIndex(this.quiz);
    }

    this.selectQuestion(this.questionIndex);
    this.setLoaderActivity(false);
  },

  methods: {
    ...mapActions('viewer', {
      setActivityAsStarted: 'setActivityAsStarted',
      setQuizAsStarted: 'setQuizAsStarted',
    }),

    ...mapMutations('viewer', {
      setAsRejected: 'setAsRejected',
      setAsCompleted: 'setAsCompleted',
      setNumberOfAttempts: 'setNumberOfAttempts',
      setScore: 'setScore',
    }),

    ...mapMutations('loader', {
      setLoaderActivity: 'setLoaderActivity',
    }),

    async getQuiz() {
      const parentId = this.activity._id;
      const { programId } = this.$route.params;

      try {
        const response = await Program.getMaterials(programId, parentId);
        this.quiz = response.data;
      } catch (e) {
        console.log('e', e);
      }
    },

    async submitAnswers() {
      if (this.answerPending) return;
      const questionId = this.question._id;

      const data = {
        parent_statistic_id: this.question.statistic.parent_statistic_id,
        answers: this.selectedAnswers,
      };

      try {
        this.answerPending = true;
        await this.checkActivityAsStarted();

        const response = await Program.sendAnswers(questionId, data);

        this.question.statistic.meta = response.data;
        this.question.statistic.status = ACTIVITY_STATUSES.done;

        this.answerPending = false;
        if (this.isLastQuestion) {
          this.changeStatus();
          this.changeScore();
        }
      } catch (e) {
        console.log('e', e);
        this.answerPending = false;
      }
    },

    scrollPageToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },

    selectQuestion(index = 0) {
      this.question = null;
      this.questionIndex = index;
      this.scrollPageToTop();
      setTimeout(() => {
        this.question = this.quiz[this.questionIndex];
        this.loading = false;
      });
    },

    selectAnswer(answerId) {
      if (this.question.meta.is_multiple) {
        this.selectMultiAnswer(answerId);
      } else {
        this.selectSingleAnswer(answerId);
      }
    },

    selectSingleAnswer(answerId) {
      this.question.meta.answers = this.question.meta.answers.map((answer) => {
        if (answer.id === answerId) {
          answer.is_selected = !answer?.is_selected;
        } else {
          answer.is_selected = false;
        }
        return answer;
      });
    },

    selectMultiAnswer(answerId) {
      this.question.meta.answers = this.question.meta.answers.map((answer) => {
        if (answer.id === answerId) {
          answer.is_selected = !answer?.is_selected;
        }
        return answer;
      });
    },

    getFirstUncompletedIndex(questions) {
      const firstNotCompletedQuestionIndex = questions.findIndex(
        (q) => q.statistic.status !== ACTIVITY_STATUSES.done,
      );

      return (firstNotCompletedQuestionIndex !== -1) ? firstNotCompletedQuestionIndex : 0;
    },

    viewAnswers() {
      this.selectQuestion(0);
      this.showResult = false;
    },

    async replayActivity() {
      this.quiz.map((question) => {
        question.statistic.status = ACTIVITY_STATUSES.notStarted;

        question.meta.answers.map((answer) => {
          answer.is_selected = false;
          answer.is_correct = false;
          return answer;
        });
        return question;
      });
      this.selectQuestion(0);
      this.showResult = false;
    },

    async checkActivityAsStarted() {
      if (this.questionIndex === 0) {
        try {
          await this.setQuizAsStarted({
            activityId: this.activity._id,
            statisticId: this.activity.statistic._id,
          });

          if (this.activity.meta.attempts) {
            const usedAttempts = this.activity.statistic?.meta?.used_attempts || 0;

            this.setNumberOfAttempts({
              materialId: this.activity._id,
              numberOfAttempts: usedAttempts + 1,
            });
          }
        } catch (e) {
          console.log('e', e);
        }
      }
    },

    changeStatus() {
      const status = (this.score < this.activity.meta.passing_score)
        ? ACTIVITY_STATUSES.rejected
        : ACTIVITY_STATUSES.done;

      if (status === ACTIVITY_STATUSES.done) {
        this.setAsCompleted(this.activity._id);
      } else {
        this.setAsRejected(this.activity._id);
      }
    },

    changeScore() {
      this.setScore({
        materialId: this.activity._id,
        score: this.score,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
