import { HTTP } from './default';

export default {
  getUserInfo() {
    return HTTP({
      // url: '/v1/auth/whoami',
      url: '/api/auth/',
      method: 'GET',
    });
  },
  saveUserInfo({ firstName, lastName, email }) {
    return HTTP({
      // url: '/v1/auth/whoami',
      url: '/api/auth/',
      method: 'POST',
      data: { first_name: firstName, last_name: lastName, email },
    });
  },
  onRegistrationSuccess() {
    return HTTP({
      url: '/api/registration-success',
      method: 'POST',
      data: {},
    });
  },
  onPasswordChanged() {
    return HTTP({
      url: '/api/password-changed',
      method: 'POST',
      data: {},
    });
  },
};
