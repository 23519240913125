<template>
  <div>
    <v-virtual-scroll
      ref="virtualScroll"
      :item-height="itemHeight"
      :items="currentPageRowsItems"
      bench="1"
      :height="heightComputed"
      :min-width="0"
    >
      <template v-slot="{ item: row, index: rowIndex }">
        <v-container>
          <v-row :key="`${rowIndex}`" :justify="justifyComputed" no-gutters>
            <div v-for="(card, columnIndex) in row" :key="`${rowIndex}#${columnIndex}`">
              <v-scale-transition hide-on-leave>
                <div :key="card.name === 'dummy' ? 'd' : 'i'">
                  <slot ref="card" :item="card" :index="`${rowIndex}#${columnIndex}`" />
                </div>
              </v-scale-transition>
            </div>
          </v-row>
        </v-container>
      </template>
    </v-virtual-scroll>

    <v-container class="pa-0 pb-8">
      <v-row :justify="justifyComputedPagination" no-gutters>
        <v-pagination
          v-if="rowsChunksByPages.length > 1"
          v-model="page"
          :total-visible="5"
          :length="rowsChunksByPages.length"
          class=""
        ></v-pagination>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'RcItemsList',

  props: {
    items: {
      type: Array,
      default: undefined,
      required: true,
    },
    itemHeight: {
      type: [String, Number],
      default: 430,
    },
    height: {
      type: [String, Number],
      default: 500,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    justify: {
      type: String,
      default: undefined,
    },
    itemsPerRow: {
      type: [String, Number],
      default: undefined,
    },
  },

  data: () => ({
    mounted: false,
    page: 1,
    rowsPerPage: 5,
  }),

  computed: {
    justifyComputed() {
      if (this.justify) {
        return this.justify;
      }
      let result = 'space-between';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          result = 'space-around';
          break;

        case 'md':
        case 'lg':
        case 'xl':
          result = 'space-between';
          break;

        // no default
      }
      return result;
    },

    justifyComputedPagination() {
      return this.$vuetify.breakpoint.lg ? 'end' : 'center';
    },

    heightComputed() {
      if (!this.fullHeight) {
        return this.height;
      }

      return this.itemHeight * this.currentPageRowsItems.length + 25;
    },

    itemsPerRowComputed() {
      if (this.itemsPerRow) {
        return this.itemsPerRow;
      }
      let result = 1;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          result = 1;
          break;

        case 'sm':
        case 'md':
          result = 2;
          break;

        case 'lg':
        case 'xl':
          result = 3;
          break;

        // no default
      }
      return result;
    },

    allRows() {
      return this.items.reduce((acc, curr, index) => {
        const rowIndex = Math.floor(index / this.itemsPerRowComputed);
        let row = acc[rowIndex];
        if (!row) {
          acc[rowIndex] = [];
          row = acc[rowIndex];
        }
        row.push(curr);
        return acc;
      }, []);
    },

    rowsChunks() {
      return this.chunk(this.allRows);
    },

    rowsChunksByPages() {
      return this.chunk(this.rowsChunks, this.rowsPerPage);
    },

    currentPageRowsItems() {
      let result = [];
      if (this.page - 1 > this.rowsChunksByPages.length) {
        result = [...this.rowsChunksByPages[0].flat()];
      } else if (Array.isArray(this.rowsChunksByPages[this.page - 1])) {
        result = [...this.rowsChunksByPages[this.page - 1].flat()];
      }

      // fill last row if it doesn't fit
      let lastRow = result.pop();

      if (!lastRow) {
        return result;
      }

      if (lastRow.length < this.itemsPerRowComputed) {
        const emptyArray = Array.from({
          length: this.itemsPerRowComputed - lastRow.length,
        }).map(() => ({
          name: 'dummy',
        }));
        lastRow = [...lastRow, ...emptyArray];
      }
      result.push(lastRow);
      return result;
    },
  },
  watch: {
    rowsChunksByPages: {
      handler(value) {
        if (this.page - 1 > value.length) {
          this.page = value.length;
        }
      },
    },
    page() {
      this.$emit('pageChange');
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.mounted = true;
    });
  },

  methods: {
    chunk(array, size = 1) {
      const chunkedValues = [];

      for (let i = 0; i < array.length; i += size) {
        chunkedValues.push(array.slice(i, i + size));
      }
      return chunkedValues;
    },
  },
};
</script>
