import { HTTP } from './default';

export default {
  signIn(data) {
    return HTTP({
      url: '/api/auth/login',
      method: 'POST',
      data,
    });
  },

  signUp(data) {
    return HTTP({
      url: '/api/auth/signup',
      method: 'POST',
      data,
    }).then((resp) => resp.data.status === 'success').catch(() => false);
  },

  refresh() {
    return HTTP({
      url: '/api/auth/refresh',
      method: 'POST',
    }).then((resp) => resp.data.status === 'success').catch(() => false);
  },

  signOut() {
    return HTTP({
      url: '/api/auth/logout',
      method: 'POST',
    }).then((resp) => resp.data.status === 'success').catch(() => false);
  },

  resetPassword(email) {
    return HTTP({
      url: '/v1/auth/forgot_password',
      method: 'POST',
      data: {
        email,
      },
    });
  },

  setPassword(data) {
    return HTTP({
      url: '/v1/auth/reset_password',
      method: 'PATCH',
      data,
    });
  },

  checkEmail(email) {
    return HTTP({
      url: '/v1/auth/check_email',
      method: 'POST',
      data: {
        email,
      },
    });
  },
};
