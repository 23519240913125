const isValidFileFormat = (fileName) => {
  const extension = fileName
    .split('.')
    .pop();
  switch (extension.toLowerCase()) {
    case 'pptx':
    case 'docx':
    case 'pdf':
    case 'zip':
      return true;
    default:
      return false;
  }
};

const isValidFileSize = (fileSize) => fileSize <= 104857600;

// eslint-disable-next-line import/prefer-default-export
export const checkFile = (file) => {
  if (!isValidFileFormat(file.name)) {
    return {
      title: 'Файл не поддерживается',
      message: 'Вы можете загрузить только файлы формата .pptx, .docx, .pdf, .zip',
    };
  }

  if (!isValidFileSize(file.size)) {
    return {
      title: 'Слишком большой размер файла',
      message: 'Максимальный размер файла, который можно загрузить - 100 MB',
    };
  }

  return '';
};
