<template>
  <div class="login__panel">
    <router-link to="/auth" class="close"></router-link>
    <div class="login__content">
      <div class="block-title">Забыли пароль?</div>
      <div class="login__text">Введите адрес почты, и мы поможем восстановить ваш пароль</div>
    </div>
    <div class="login__form">
      <label class="field">
        <input
          v-model.trim="email"
          type="email"
          class="field__value"
          placeholder="Электронная почта"
          @keyup.enter="submit"
        >
        <span class="field__label">Электронная почта</span>
        <span
          v-if="$v.email.$dirty && !$v.email.required"
          class="field__error"
        >
                Заполните поле
              </span>
        <span
          v-else-if="$v.email.$dirty && !$v.email.email"
          class="field__error"
        >
                Некорректный E-mail
              </span>
        <span v-else-if="error" class="field__error">
                {{ error }}
              </span>
      </label>
      <div class="login__button">
        <button
          v-if="!loading"
          class="button button--full"
          @click="submit"
        >
          Сбросить пароль
        </button>
        <button v-else class="button button--full button--disabled button--preloader"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'ForgotPasswordCard',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      email: '',
    };
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    submit() {
      this.$v.email.$touch();
      if (!this.$v.$invalid) {
        this.$emit('submit', this.email);
      }
    },
  },
};
</script>

<style scoped>

</style>
