import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { VueMaskDirective } from 'v-mask';
import LeraVueLibrary from '@centergame/lera-vue-library';
import App from './App.vue';
import router from './router';
import store from './store';
// eslint-disable-next-line import/extensions
import ScrollAnimation from './core/directives/scrollanimation.js';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(LeraVueLibrary);
Vue.use(Vuelidate);
Vue.directive('scrollanimation', ScrollAnimation);
Vue.directive('mask', VueMaskDirective);

const startApp = () => {
  const vueApp = new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');

  const isDev = (process.env.VUE_APP_ENV === 'staging' || !process.env.VUE_APP_ENV);
  if (isDev) {
    window.app = vueApp;
  }
};

store.dispatch('appStart')
  .then(() => {
    startApp();
  });
