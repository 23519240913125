<template>
  <div class="theme">
    <div
        class="theme__header"
        :class="{'theme__header--active': isOpen}"
        @click="isOpen = !isOpen"
    >
      <div class="theme__title">
        {{ group.activity.description }}
      </div>
      <div class="status">
        {{ courseLabel }}
        <component :is="courseStatus"></component>
      </div>
    </div>
    <VueSlideToggle :open="isOpen" :duration="300">
      <div class="theme__body">
        <Material
            v-for="(activity, index) in group.children"
            :key="activity.activity._id"
            :material="activity.activity"
            :index="index"
        />
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import Material from '@/components/course/group/Material.vue';
import ProgressDone from '@/components/icons/statuses/ProgressDone.vue';
import ProgressNotStarted from '@/components/icons/statuses/ProgressNotStarted.vue';
import ProgressDoing from '@/components/icons/statuses/ProgressDoing.vue';
import { VueSlideToggle } from 'vue-slide-toggle';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';
import StatusFail from '@/components/icons/statuses/StatusFail.vue';

export default {
  name: 'GroupSlide',

  components: {
    Material,
    VueSlideToggle,
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    courseStatus() {
      const { status } = this.group.activity.statistic;

      switch (status) {
        case ACTIVITY_STATUSES.done:
          return ProgressDone;
        case ACTIVITY_STATUSES.notStarted:
          return ProgressNotStarted;
        case ACTIVITY_STATUSES.rejected:
          return StatusFail;
        default:
          return ProgressDoing;
      }
    },

    courseLabel() {
      const { status } = this.group.activity.statistic;

      switch (status) {
        case ACTIVITY_STATUSES.done:
          return 'Просмотрен';
        case ACTIVITY_STATUSES.notStarted:
          return 'Не начат';
        case ACTIVITY_STATUSES.rejected:
          return 'Отклонено';
        default:
          return 'В процессе';
      }
    },
  },
};
</script>

<style scoped>
</style>
