<template>
  <v-card v-if="!isItAContestCourseId" max-width="655px" class="px-5 pt-3 pb-2 mb-3">
    <v-row class="pa-0 ma-0 d-flex align-center">
      <v-col class="pa-0 pr-2 ma-0">
        <v-row class="pa-0 ma-0 mb-1" justify="space-between">
          <div
            class="text-body-2 font-weight-bold"
            :class="
              activity.statistic.status === ACTIVITY_STATUSES.rejected
                ? 'error--text'
                : 'success--text'
            "
          >
            {{ statusTextComputed }}
          </div>
          <!--
          <div v-if="maxScoreComputed">{{ currentScoreComputed }}/{{ maxScoreComputed }}
            балов
          </div>
-->
        </v-row>
        <v-progress-linear
          class="mb-3"
          :color="activity.statistic.status === ACTIVITY_STATUSES.rejected ? 'error' : 'success'"
          :value="progressComputed"
          :height="14"
          rounded
        />
      </v-col>
      <div class="progress--percent ml-2" style="max-width: 100px">{{ progressComputed }}%</div>
    </v-row>
  </v-card>
</template>

<script>
import progressMixin from '@/mixins/progress.mixin';
import { mapGetters } from 'vuex';
import { ACTIVITY_STATUSES } from '@/core/constants/statuses';

export default {
  name: 'RcCourseProgressLinear',
  mixins: [progressMixin],
  computed: {
    ACTIVITY_STATUSES() {
      return ACTIVITY_STATUSES;
    },
    ...mapGetters('viewer', ['isItAContestCourseId']),
  },
};
</script>

<style scoped>
.progress--percent {
  font-weight: 700;
  font-size: 36px;
  margin-top: 2px;
}
</style>
