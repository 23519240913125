<template>
  <img
    src="@/assets/images/icons/icon__status--fail.svg"
    alt="Отклонено"
  >
</template>

<script>
export default {
  name: 'StatusFail',
};
</script>

<style scoped>

</style>
