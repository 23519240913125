<template>
  <v-select
    v-model="selectedEducation"
    :options="options"
    :clearable="false"
    @input="$emit('input', selectedEducation)"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes"></span>
    </template>
    <template #search="{ attributes, events }">
      <input
        class="vs__search"
        v-bind="attributes"
        placeholder="Образование"
        v-on="events"
      />
      <div class="field__label">Образование</div>
    </template>
    <template #no-options="{}">
      Не найдено
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'EducationSelector',

  components: {
    vSelect,
  },

  data() {
    return {
      selectedEducation: '',
      options: [
        'Среднее общее',
        'Высшее бакалавриат',
        'Высшее специалитет, магистратура',
        'Высшее подготовка кадров высшей квалификации',
        'Ученая степень'],
    };
  },
};
</script>

<style scoped>

</style>
