<template>
  <main class="main main-main">
    <SectionBanner v-scrollanimation="{className: 'section--animated'}"/>

    <SectionWhom v-scrollanimation="{className: 'section--animated'}"/>

    <SectionGet v-scrollanimation="{className: 'section--animated'}"/>

    <SectionJoin v-scrollanimation="{className: 'section--animated'}"/>

  </main>
</template>

<script>
import SectionBanner from '@/components/mainGuest/sections/SectionBanner.vue';
import SectionWhom from '@/components/mainGuest/sections/SectionWhom.vue';
import SectionGet from '@/components/mainGuest/sections/SectionGet.vue';
import SectionJoin from '@/components/mainGuest/sections/SectionJoin.vue';

export default {
  name: 'About',

  components: {
    SectionBanner,
    SectionWhom,
    SectionGet,
    SectionJoin,
  },
};
</script>

<style lang="less">
@import "~@/assets/styles/pages/main.less";
</style>
