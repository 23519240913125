<template>
  <h1
    class="text-h2 text-lg-h1 mb-0 mb-md-5 mt-10 mt-lg-16 text-center text-sm-start"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'RcPageTitle',

  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
</style>
