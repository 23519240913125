<template>
  <v-app-bar app class="header" color="white" height="48">
    <router-link to="/" class="logo"></router-link>
    <v-container v-if="showMenu">
      <Menu />
    </v-container>
    <div class="header__right">
      <MenuDropdown v-if="isAuthenticated" />
      <div v-else class="header-login">
        <div class='row justify-center'>
          <div class="hesk-button" :class="{ active: showForm }" @click="showForm = !showForm">
            Техническая поддержка
          </div>
        </div>
        <router-link to="/auth" class="header-login__button">
          <div class="header-login__link">Вход</div>
        </router-link>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import Menu from '@/components/header/Menu.vue';
import { mapGetters, mapState } from 'vuex';
import MenuDropdown from '@/components/header/MenuDropdown.vue';
import heskMixin from '@/mixins/hesk.mixin';

export default {
  name: 'Header',

  components: {
    Menu,
    MenuDropdown,
  },

  mixins: [heskMixin],

  computed: {
    ...mapGetters('user', {
      isAuthenticated: 'isAuthenticated',
    }),

    ...mapState('user', {
      user: 'user',
    }),

    showMenu() {
      const isMobile = this.$vuetify.breakpoint.mobile;
      return this.isAuthenticated && !isMobile;
    },
  },
};
</script>

<style scoped>
.logo {
  min-width: 38px;
  margin-right: unset;
}
</style>
