<template>
  <img
    src="@/assets/images/icons/icon__material-document--x2.png"
    alt="Документ"
  >
</template>

<script>
export default {
  name: 'DocxIcon',
};
</script>

<style scoped>

</style>
