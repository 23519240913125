<template>
  <div>
    <RcAnimate>
    <v-container>
      <RcPageTitle>Мое обучение</RcPageTitle>
      <v-sheet
        max-width="730"
        color="transparent"
        class="my-5"
      >
        <p class="text-body-1 font-weight-medium">
          <span class="black--text">
            {{ fullName }},
          </span>
          <span class="grey--text">
            с возвращением! Сегодня отличный день,
            чтобы продолжить обучение и выполнить
            домашнее задание
          </span>
        </p>
      </v-sheet>

      <RcMyProfile max-width="730"/>
    </v-container>
    <RcGroupSlider
      v-slot="{ item }"
      :items="coursesInProgressList"
      class="mt-9"
      title="Курсы в процессе"
    >
      <RcItemCard
        :key="item._id"
        :activity="item"
        :to="`/course-user/${item._id}/${item.programId}`"
        image-label="Курс"
      />
    </RcGroupSlider>
    </RcAnimate>
  </div>
</template>

<script>
import RcAnimate from '@/components/rc/RcAnimate.vue';
import RcPageTitle from '@/components/rc/RcPageTitle.vue';
import RcMyProfile from '@/components/rc/my/RcMyProfile.vue';
import RcGroupSlider from '@/components/rc/RcGroupSlider.vue';
import RcItemCard from '@/components/rc/RcItemCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MyIndex',

  components: {
    RcPageTitle,
    RcMyProfile,
    RcGroupSlider,
    RcItemCard,
    RcAnimate,
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters('user', [
      'fullName',
    ]),
    ...mapGetters('courses', [
      'coursesInProgressList',
    ]),
  },
};
</script>
