<template>
  <div class="list" :class="{'list--hidden': !isVisible}">
    <div class="list__title">Содержание</div>
    <div class="close" @click="$emit('hide')"></div>
    <div v-if="materials" class="list__group">
      <MaterialListItem
        v-for="(material) in materials"
        :key="material._id"
        :material="material"
        :class="{'list__item--current': selectedMaterialIndex === material._id}"
        @click.native="selectMaterial(material._id)"
      />
    </div>
    <div v-else class="list__group">
      <div class="list-group-loader">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialListItem from '@/components/viewer/MaterialListItem.vue';
import deviceMixin from '@/mixins/device.mixin';
import Loader from '@/components/Loader.vue';

export default {
  name: 'MaterialList',

  components: {
    MaterialListItem,
    Loader,
  },

  mixins: [deviceMixin],

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    materials: {
      type: Array,
      required: true,
    },
  },

  computed: {
    selectedMaterialIndex() {
      return Number(this.$route.query.activityId);
    },
  },

  methods: {
    selectMaterial(activityId) {
      const currentActivityId = Number(this.$route.query.activityId);

      if (!this.$device.isDesktop) {
        this.$emit('hide');
      }

      if (currentActivityId === activityId) return;

      this.$router.replace({ query: { activityId } });
    },
  },
};
</script>

<style scoped>
.list-group-loader {
  margin-top: 48px;
}
</style>
