<template>
  <v-app>
    <router-view />
    <Hesk />
  </v-app>
</template>

<script>
import Hesk from '@/components/hesk/Hesk.vue';

export default {
  components: { Hesk },
};
</script>

<style lang="less">
@import "@/assets/styles/global/compiled.less";
</style>
