<template>
  <img
    src="@/assets/images/icons/icon__material-video--x2.png"
    alt="Видео"
  >
</template>

<script>
export default {
  name: 'VideoIcon',
};
</script>

<style scoped>

</style>
