<template>
  <a :href="file.url" target="_blank" class="document">
    <img src="@/assets/images/icons/icon__material-pdf.svg" alt="Иконка" class="document__icon">
    {{ file.name }}
  </a>
</template>

<script>
export default {
  name: 'Document',

  props: {
    file: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
