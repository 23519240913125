export default {
  namespaced: true,

  state: {
    isFormOpened: false,
  },

  mutations: {
    setHeskFormState(state, isActive) {
      state.isFormOpened = isActive;
    },
  },

  getters: {
    getIsFormOpened: ({ isFormOpened }) => isFormOpened,
  },
};
