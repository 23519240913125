<template>
  <div v-if="!isLoaderShown" class="testing__panel">
    <h3 class="block-subtitle"> {{ blockSubtitle }} </h3>
    <label class="field">
        <textarea
            v-model="answer"
            cols="30"
            rows="8"
            class="field__value"
            placeholder="Напишите своей ответ..."
        >
        </textarea>
      <span class="field__label">Напишите своей ответ...</span>
    </label>
    <div v-if="activity.meta.url" class="buttons">
      <LinkToSample :url="activity.meta.url"/>
    </div>
    <div class="testing__footer">
      <div
          v-if="file"
          class="uploaded"
      >
        <div class="uploaded__text">{{ file.name }}</div>
        <div class="uploaded__remove" @click="removeFile"></div>
      </div>
      <label v-show="!file && isTaskWithFileUpload" class="button button--hollow">
        Прикрепить файл
        <img
            src="@/assets/images/icons/icon__attachment--red.svg"
            alt="Вложение"
            class="button__icon"
        >
        <input
            id="file-input"
            ref="fileInput"
            type="file"
            class="button__file"
            accept=".pdf, .docx, .pptx, .zip"
            @change="uploadFile"
        >
      </label>
      <button
          class="button"
          :class="{'button--disabled': !answer}"
          @click="sendAnswer"
      >
        Отправить ответ
      </button>
    </div>
  </div>
</template>

<script>
import LinkToSample from '@/components/viewer/taskViewer/LinkToSample.vue';
import Program from '@/api/program';
import {
  mapActions, mapState, mapMutations, mapGetters,
} from 'vuex';
import { checkFile } from '@/core/helpers/checkFile';
import toastMixin from '@/mixins/toast.mixin';

export default {
  name: 'TaskViewer',

  components: {
    LinkToSample,
  },

  mixins: [toastMixin],

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      answer: '',
      file: null,
    };
  },

  computed: {
    ...mapState('loader', {
      isLoaderShown: 'isLoaderShown',
    }),
    ...mapGetters('viewer', [
      'isItAContestIntroductionTask',
    ]),
    isTaskWithFileUpload() {
      return !this.isItAContestIntroductionTask;
    },
    blockSubtitle() {
      let result = 'Запиши свой ответ в поле ниже.';

      if (this.isTaskWithFileUpload) {
        result += ' Ты также можешь прикрепить файл.';
      }

      return result;
    },
  },

  methods: {
    ...mapActions('viewer', {
      setActivityAsStarted: 'setActivityAsStarted',
    }),

    ...mapMutations('loader', {
      setLoaderActivity: 'setLoaderActivity',
    }),

    async sendAnswer() {
      this.setLoaderActivity(true);

      const taskId = this.activity._id;
      const {
        programId,
        courseId,
      } = this.$route.params;
      const activityContext = [programId, courseId];

      const data = {
        activity_context: activityContext,
        text: this.answer,
        message_type: 1,
      };

      if (this.file) {
        data.attachment_url = await this.sendMaterial();
        data.attachment_name = this.file.name;
      }

      await this.createChat(taskId, activityContext);

      await this.sendMessage(taskId, data);

      await this.setActivityAsStarted(this.activity._id);

      this.setLoaderActivity(false);
    },

    async sendMaterial() {
      const response = await Program.sendFile(this.file);
      return response.data.url;
    },

    async createChat(taskId, activityContext) {
      await Program.createChat(taskId, activityContext);
    },

    async sendMessage(taskId, data) {
      await Program.sendMessage(taskId, data);
    },

    uploadFile(event) {
      const [file] = event.target.files;
      if (!file) return;

      const error = checkFile(file);
      if (error) {
        this.showError(error);
      } else {
        this.file = file;
        this.$refs.fileInput.value = '';
      }
    },

    removeFile() {
      this.file = null;
    },
  },
};
</script>

<style scoped>

</style>
