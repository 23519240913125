<template>
  <v-img
    v-intersect.once="{
      handler: onIntersect.bind(this),
      options: {
        threshold: 0.1,
      }
    }"
    :class="classObject"
    contain
    max-width="100vw"
    position="top right"
    :src="this.$vuetify.breakpoint.mobile ? srcMobile : src"
  >
    <slot />
  </v-img>
</template>

<script>
export default {
  name: 'RcAnimate',

  data: () => ({
    showImage: false,
    src: require('@/assets/images/designs/background__catalog.svg'),
    srcMobile: require('@/assets/images/designs/background__my__xs.svg'),
    isMounted: false,
  }),

  computed: {
    classObject() {
      const result = [
        'rc-animate',
      ];
      if (this.showImage) {
        result.push('rc-animate__show');
      }
      return result;
    },
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    onIntersect(entries, observer, isIntersecting) {
      setTimeout(() => {
        this.showImage = this.isMounted && isIntersecting;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.rc-animate {
  &::v-deep > .v-image__image {
    transition: all 2s ease-in-out;
    position: absolute;
    top: 10px;

    left: 45vw;
    width: 55vw;

    opacity: 0;
    transform: translateX(100px);
  }

  &__show::v-deep > .v-image__image {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
