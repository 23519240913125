<template>
  <main class="main main-main">

    <SectionDiagnostic />

    <SectionBanner
      v-intersect.once="{ handler: animateSection, options:{ threshold: 0.5, } }"
    />

    <SectionAcademy
      v-intersect.once="{ handler: animateSection, options:{ threshold: 0.25, } }"
    />

    <SectionWhom />

    <SectionGet
      v-intersect.once="{ handler: animateSection, options:{ threshold: 0.3, } }"
    />

    <SectionJoin
      v-intersect.once="{ handler: animateSection, options:{ threshold: 0.8, } }"
    />

  </main>
</template>

<script>
import SectionBanner from '@/components/mainGuest/sections/SectionBanner.vue';
import SectionDiagnostic from '@/components/mainGuest/sections/SectionDiagnostic.vue';
import SectionAcademy from '@/components/mainGuest/sections/SectionAcademy.vue';
import SectionWhom from '@/components/mainGuest/sections/SectionWhom.vue';
import SectionGet from '@/components/mainGuest/sections/SectionGet.vue';
import SectionJoin from '@/components/mainGuest/sections/SectionJoin.vue';

export default {
  name: 'MainGuest',

  components: {
    SectionBanner,
    SectionDiagnostic,
    SectionAcademy,
    SectionWhom,
    SectionGet,
    SectionJoin,
  },

  methods: {
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#creating_an_intersection_observer
     */
    animateSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('section--animated');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: #F9F9F9;
}
</style>
