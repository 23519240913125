import { render, staticRenderFns } from "./LinkToSample.vue?vue&type=template&id=4b01cb04&scoped=true&"
import script from "./LinkToSample.vue?vue&type=script&lang=js&"
export * from "./LinkToSample.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b01cb04",
  null
  
)

export default component.exports